import React, { useState, useEffect, Suspense, lazy } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { animated } from "react-spring";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { useRecoilState } from "recoil";
import { sectionClassIdAtom } from "../../../_store/class/store";
import { ApiClient } from "../../../_axios/";
import {
  notificationAtom,
  notificationOptions,
} from "../../../_store/notification/store";
import SchoolResultTable from "./SchoolResultTable";

const StudentList = lazy(() => import("./StudentList"));

function SchoolResult() {
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const { classId } = useParams<{ classId: string }>();
  const [, setSectionClassId] = useRecoilState(sectionClassIdAtom);
  const [studentList, setStudentList] = useState([]);
  const pageTransition = useCustomTransition();
  const [, setNotification] = useRecoilState(notificationAtom);


  useEffect(() => {
    async function getCurrentSection() {
      try {
        const sectionResponse = await ApiClient.getSection(classId);
        const studentResponse = await ApiClient.getStudentList(classId);

        setSectionClassId(sectionResponse.data.section_class);
        setStudentList(studentResponse.data);
      } catch {
        setNotification({
          type: notificationOptions.ERROR,
          text: "Could not fetch section/user data.",
          visible: true,
        });
      }
    }

    getCurrentSection();
  }, []);

  

  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <ClassWrapper>
        <ContentWrapper>
          <Suspense
            fallback={
              <div className="fetching_info">Fetching school data...</div>
            }
          >
            <SchoolResultTable classid={classId} />
          </Suspense>
        </ContentWrapper>
      </ClassWrapper>
    </animated.div>
  );
}

export default SchoolResult;

const ClassWrapper = styled.div`
  // display: flex;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
  }
`;

const ContentWrapper = styled.div`
  overflow: scroll;

  .fetching_info {
    margin-top: 3rem;
    margin-left: 2rem;
  }
`;
