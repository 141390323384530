import React, { useState } from "react";
import styled from "@emotion/styled";
import CSRFToken from "./CSRFToken";
import logo from "../../../_assets/images/logo/logo.png";
import bg from "../../../_assets/images/login-bg.jpg";
import { useLogin } from "../../../_customHooks/auth";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { animated } from "react-spring";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const loginUser = useLogin({ username, password });
  const pageTransition = useCustomTransition();
  useMetaTags(defaultOptions.LOGIN);

  const login = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginUser();
  };

  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <LoginWrapper>
        <FormWindowOuter>
        <FormWindow>
          <Header>
            <Logo />
          </Header>
          <Form onSubmit={login}>
            <InputWrapper>
              <Label htmlFor="username">Username:</Label>
              <Input
                type="text"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor="password">Password:</Label>
              <Input
                type="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </InputWrapper>

            <CSRFToken />
            <SubmitButton type="submit">Login</SubmitButton>
          </Form>
        </FormWindow>
        </FormWindowOuter>
      </LoginWrapper>
    </animated.div>
  );
}

export default Login;

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
`;

const FormWindowOuter = styled.div`
  width: 26.125rem;
  max-width: 100%;
  height: 18.065rem;
  max-height: 100%;
  background-color: var(--login-card);
  box-shadow: var(--box-shadow-default);
  border-radius: var(--border-radius);
  // border: 1px solid #707070;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-bottom: 10%;
`;

const FormWindow = styled.div`
  width: 26.125rem;
  max-width: 100%;
  height: 18.065rem;
  max-height: 100%;
  background-color: var(--neutral-000);
  box-shadow: var(--box-shadow-default);
  border-radius: var(--border-radius);
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--neutral-000);
  padding: 0.625rem 1.25rem;
  // margin-bottom: 1.25rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

const Logo = styled.div`
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 3.75rem;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 0.7rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 0.3125rem 6.25rem 3.125rem;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    // padding: 0.3125rem 1.25rem 1.875rem;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // padding: 0.625rem 0;
  width: 100%;
`;

const Label = styled.label`
  font-family: var(--pts-font-family);
  font-size: 1.5rem;
  font-weight: bold;
  color: #707070;
  margin-top: 1.5rem;
  margin-left: 1rem;
  // width: 100%;
  text-align: center;
`;

const Input = styled.input`
  border: 1px solid #d9d9d903;
  font-size: 1rem;
  outline: none;
  // padding: 0.625rem 1.875rem;
  border-bottom: 2px solid #707070;
  width: 100%;
  padding-left: 0.25rem;
  margin-left: 0.33rem;
  margin-top: 1.55rem;
  margin-right: 1rem;

  &:focus {
    outline: none;
    // border: 1px solid var(--secondary-600);
  }
`;

const SubmitButton = styled.button`
  margin-top: 1rem;
  // padding: 0.625rem 1.5625rem;
  width: 50%;
  font-family: var(--base-font-family);
  font-size: 1.3rem;
  color: var(--neutral-000);
  border: none;
  background-color: #3579f6;
  border-radius: var(--border-radius);
  font-weight: bold;
  transition: background-color 0.2s ease;
  cursor: pointer;
  outline: none;
  margin-bottom: 0.8rem;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;

  &:hover {
    background-color: rgb(104 151 237);
  }
`;
