import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function Spinner() {
  return (
    <FontAwesomeIcon
      className="spinner"
      icon={faSpinner}
      style={{ fontSize: "2rem", margin: "50px auto 0" }}
    />
  );
}

export default Spinner;
