import React from "react";
import styled from "@emotion/styled";
import { upcomingLessonInfo } from "./_helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faChalkboardTeacher,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { roots } from "../../../_urls";
import { ClassTypes } from "../../../_store/class/models";
import classNames from "classnames";

interface SingleClassInfoProps {
  classData: ClassTypes;
  additionalClass?: boolean;
}

function SingleClassInfo({ classData, additionalClass }: SingleClassInfoProps) {
  const history = useHistory();

  const selectSectionAndMove = () => {
    history.push(`${roots.CLASS}/${classData.id}`);
  };

  return (
    <ClassInfoWrapper
      className={classNames({
        first_group: additionalClass,
      })}
    >
      <ClassInfo>
        <ClassName>
          <FontAwesomeIcon
            className="class-name-icon"
            icon={faChalkboardTeacher}
          />
          {classData.full_name}
        </ClassName>
        <div className="class-additional-info">
          <NumOfStudents>
            <FontAwesomeIcon className="lesson-students-icon" icon={faUsers} />
            {classData.users_count}{" "}
            {classData.users_count === 1 ? "student" : "students"}
          </NumOfStudents>
          <UpcommingLessons>{upcomingLessonInfo(classData)}</UpcommingLessons>
        </div>
      </ClassInfo>
      <ClassDetailButton onClick={selectSectionAndMove}>
        <FontAwesomeIcon
          className="view-class-arrow-icon"
          icon={faAngleDoubleRight}
        />
      </ClassDetailButton>
    </ClassInfoWrapper>
  );
}

export default SingleClassInfo;

const ClassInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--box-shadow-default);
  background-color: var(--neutral-050);
  margin: 1rem 1rem 0.5rem;
  width: 100%;

  overflow: hidden;
  padding: 0.7rem 0.7rem 0.2rem;
  padding-top: 1rem;
  position: relative;

  &.first_group {
    background-color: var(--neutral-150);
    position: relative;

    &:after {
      content: "Private Tuition";
      height: 30px;
      position: absolute;
      background: var(--neutral-800);
      color: var(--neutral-000);
      width: 80px;
      top: 0;
      right: 0;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 845px) and (orientation: portrait) {
    width: 80%;
  }
`;

const ClassInfo = styled.div`
  display: flex;
  align-items: space-between;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding-right: 50px;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    align-items: center;
    flex-direction: column;
  }

  > p {
    margin-bottom: 13px;
  }

  .class-additional-info {
    display: flex;
    align-items: center;

    @media screen and (max-width: 545px) and (orientation: portrait) {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }
  }
`;

const ClassName = styled.p`
  color: var(--neutral-1000);
  margin-top: 0;
  font-weight: bold;
  font-size: 1rem;
  font-family: var(--normal-font-family);

  .class-name-icon {
    margin-right: 0.625rem;
    width: 1.4rem;
  }

  @media screen and (max-width: 545px) and (orientation: portrait) {
    width: 100%;

    .class-name-icon {
      display: none;
    }
  }
`;

const NumOfStudents = styled.p`
  margin-top: 0;
  font-size: var(--class-card-paragraph-size);
  font-family: var(--normal-font-family);
  font-size: 0.9rem;
  margin-right: 15px;

  .lesson-students-icon {
    margin-right: 0.625rem;
    width: 1.4rem;

    @media screen and (max-width: 545px) and (orientation: portrait) {
      display: none;
    }
  }
`;

const UpcommingLessons = styled.p`
  font-size: 0.8rem;
  margin-top: 0;
  font-weight: normal;
  font-style: italic;
  font-size: var(--class-card-paragraph-size);
  font-family: var(--normal-font-family);

  .next-lesson-data {
    color: var(--primary-600);
  }

  .no-next-lesson-data {
    color: var(--secondary-500);
  }

  .next-lesson-icon {
    margin-right: 0.625rem;
    width: 1.4rem;

    @media screen and (max-width: 545px) and (orientation: portrait) {
      display: none;
    }
  }
`;

const ClassDetailButton = styled.button`
  padding: var(--xl-btn-padding);
  font-size: var(--xs-btn-font-size);
  font-weight: bold;
  border: none;
 // background-color: var(--neutral-200);
  background: linear-gradient(90deg, rgba(224, 43, 31, 1) 0%, rgba(242, 144, 31, 1) 100%);
  // color: var(--neutral-1000);
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  outline: none;
  padding: var(--sm-btn-padding);
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .view-class-arrow-icon {
    margin-left: 0;
  }

  &:hover {
    background-color: var(--primary-500);
    color: var(--neutral-000);
  }
`;
