import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import brush from "../../../_assets/brushes/brush.png";
import brushActive from "../../../_assets/brushes/brush_active.png";

import { useRecoilValue, useRecoilState } from "recoil";
import {
  currentLessonIndex,
  sideMenuOpenStateAtom,
} from "../../../_store/lessons/store";
import { roots } from "../../../_urls";
import { LessonProps } from "../../../_store/lessons/models";
import { Game } from "../../../_store/game/models";
import classNames from "classnames";

const links = [
  { label: "Culture" },
  { label: "Dialogue" },
  { label: "Review" },
  { label: "Dribbling Race" },
  { label: "Raining Footballs" },
  { label: "Bubble Pop" },
  { label: "Fireworks" },
  { label: "Handwriting" },
  { label: "Block Smash" },
  { label: "Quiz Time!" },
];

function GameSideBar({ lessonData }: { lessonData: LessonProps[] }) {
  let { url } = useRouteMatch();
  const lessonNumber = useRecoilValue(currentLessonIndex);
  const { pathname } = useLocation<{ pathname: string }>();
  const [menuState, setMenuState] = useRecoilState(sideMenuOpenStateAtom);
  const [navItems, setNavItems] = useState<undefined | Game[]>(undefined);
  const [isTabletWidth, setIsTabletWidth] = useState(false);
  const pathnameArray = pathname.split("/");
  const gameNumber = pathnameArray[pathnameArray.length - 1];

  useEffect(() => {
    window.innerWidth < 998 ? setIsTabletWidth(true) : setIsTabletWidth(false);
    // eslint-disable-next-line
  }, [window.innerWidth]);

  useEffect(() => {
    if (lessonData?.length) {
      const currentLesson = lessonData.find(
        (lesson: LessonProps) => lesson.lesson_order === lessonNumber
      );

      const navItemArray =
        currentLesson &&
        currentLesson.slides.map((slideNumber: any, index: number) => ({
          gameId: slideNumber,
          label: links[index].label,
          i: index + 1,
          unlocked: slideNumber?.unlocked,
        }));
      setNavItems(navItemArray);
    }
  }, [lessonData, lessonNumber]);

  const handleToggleMenuState = () => setMenuState(!menuState);

  return (
    <LessonSideNavWrapper menuState={menuState}>
      <LessonNumber>Lesson {lessonNumber}</LessonNumber>
      <GameList>
        {navItems &&
          navItems.map((game, index) => (
            <NavLink
              to={`${url}/slide/${index + 1}`}
              onClick={handleToggleMenuState}
              className={classNames("slide-link", {
                active: +gameNumber === index + 1,
                unlocked: game.unlocked || index < +gameNumber,
              })}
              key={game.gameId.id}
            >
              {index + 1}. {game.label}
            </NavLink>
          ))}
        <div className="button-wrapper">
          <NavLink
            className="slide-redirect-link slide-redirect-index"
            to={roots.LESSONS}
          >
            Back to index
          </NavLink>
          <NavLink className="slide-redirect-link" to={roots.DASHBOARD}>
            Dashboard
          </NavLink>
          {isTabletWidth && (
            <span className="close-menu" onClick={handleToggleMenuState}>
              &#10006;
            </span>
          )}
        </div>
      </GameList>
    </LessonSideNavWrapper>
  );
}

export default GameSideBar;

const LessonSideNavWrapper = styled.div<{ menuState: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.25rem 0.625rem 1.25rem 1.25rem;
  width: var(--sidenav-width);
  height: calc(100vh - (var(--header-height)));
  overflow-y: auto;
  background-color: var(--body-background-color);

  @media screen and (max-width: 998px) {
    position: absolute;
    transition: all 0.3s ease;
    box-shadow: var(--box-shadow-default);
    left: ${({ menuState }) =>
      menuState ? 0 : "calc(var(--sidenav-width) * -1)"};
  }

  @media screen and (max-width: 998px) and (orientation: landscape) {
    position: absolute;
    transition: all 0.3s ease;
    left: ${({ menuState }) => (menuState ? 0 : "-100%")};
    width: 100%;
    height: calc(100% - (var(--header-height)));
  }
`;

const LessonNumber = styled.h3`
  font-family: var(--base-font-family);
  color: var(--neutral-1000);
  font-size: 1.7rem;
  margin: 0;
  margin-bottom: 1.25rem;
`;

const GameList = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;

  @media screen and (max-width: 998px) and (orientation: landscape) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .close-menu {
    text-align: left;
    color: var(--neutral-1000);
    margin-top: 1.5rem;
    font-size: 1.2rem;
    padding: var(--xl-btn-padding);
    cursor: pointer;

    :hover {
      color: var(--secondary-500);
      transition: color 0.3s ease;
    }

    @media screen and (max-width: 998px) and (orientation: landscape) {
      position: absolute;
      top: 0;
      right: 1rem;
      margin-top: 1rem;
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (max-width: 998px) and (orientation: landscape) {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
    }

    .slide-redirect-link {
      padding: var(--xl-btn-padding);
      text-align: left;
      margin: 1rem auto 0;
      position: relative;
      border-radius: 0.9375rem;
      color: var(--neutral-1000);
      font-family: var(--base-font-family);
      text-decoration: none;
      width: 100%;
      outline: none;
      font-size: 0.95rem;
      z-index: 1;

      @media screen and (max-width: 998px) and (orientation: landscape) {
        font-size: 1rem;
        margin-left: 0;
        margin-right: 1rem;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:before {
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: block;
        position: absolute;
        background-image: url(${brush});
        background-size: 100% 90%;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
      }

      &:nth-of-type(even) {
        &:before {
          transform: scaleX(-1);
        }

        @media screen and (max-width: 998px) and (orientation: landscape) {
          position: relative;
          top: 2px;
        }
      }

      &.slide-redirect-index {
        margin-top: 1.25rem;
      }
    }
  }

  .slide-link {
    margin-bottom: 6px;
    padding: 0.625rem 1.4625rem;
    position: relative;
    display: block;
    text-decoration: none;
    font-weight: bold;
    color: var(--neutral-1000);
    font-size: 0.95rem;
    z-index: 1;
    width: fit-content;
    opacity: 0.3;

    transition: all 0.2s ease;

    @media screen and (max-width: 998px) {
      padding: 0.425rem 1.3625rem;
    }

    @media screen and (max-width: 998px) and (orientation: landscape) {
      width: fit-content;
      font-size: 1rem;
      margin: 0.5rem;
    }

    &.active {
      opacity: 1;

      :before {
        background-image: url(${brushActive});
      }
    }

    &.unlocked {
      opacity: 1;
    }

    &:before {
      content: "";
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: block;
      position: absolute;
      background-image: url(${brush});
      background-size: 100% 90%;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
    }

    &:nth-of-type(even) {
      &:before {
        transform: scaleX(-1);
      }
    }
  }
`;
