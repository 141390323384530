import React from "react";
import { User } from "../../../_store/auth/models";
import { ClassTypes } from "../../../_store/class/models";
import { roles } from "../../../_helpers/roles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarTimes,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

export const attachProperTitle = (user: User | null) => {
  switch (user?.userprofile?.role) {
    case roles.TEACHER:
      return "Teacher Dashboard - Classes";
    case roles.WEB_ADMIN:
      return "Web Administrator Dashboard - Classes";
    default:
      // return "School Administrator Dashboard - Classes";
      return user?.username;
  }
};

export const upcomingLessonInfo = (classInfo: ClassTypes) => {
  if (classInfo.next_available_lesson_date && !classInfo.manual_unlock_enabled) {
    return (
      <span className="next-lesson-data">
        <FontAwesomeIcon className="next-lesson-icon" icon={faCalendarCheck} />
        next lesson:{" "}
        {format(
          new Date(classInfo.next_available_lesson_date),
          "dd MMM YYY HH:MM"
        )}
      </span>
    );
  }

  return (
    <span className="no-next-lesson-data">
      <FontAwesomeIcon className="next-lesson-icon" icon={faCalendarTimes} />
      next lesson: No upcoming lessons
    </span>
  );
};
