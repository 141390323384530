import { User } from "../_store/auth/models";
import { roles } from "../_helpers/roles";

import NotFound from "../pages/CommonPages/404/404";
import StudentDashboard from "../pages/StudentPages/Dashboard/Dashboard";
import AdminDashboard from "../pages/AdminPages/Dashboard/Dashboard";

export const attachDashboardToRole = (user: User | null) => {
  switch (user?.userprofile?.role) {
    case roles.STUDENT:
      return StudentDashboard;
    case roles.WEB_ADMIN:
    case roles.TEACHER:
    case roles.SCHOOL_ADMIN:
      return AdminDashboard;
    default:
      return NotFound;
  }
};

export const webAdminPermissionCheck = (user: User | null, component: any) => {
  switch (user?.userprofile?.role) {
    case roles.WEB_ADMIN:
    case roles.SCHOOL_ADMIN:
      return component;
    default:
      return NotFound;
  }
};
