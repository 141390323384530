import React, { useState } from "react";
import styled from "@emotion/styled";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import getAnalytics from "../../../_customHooks/getAnalytics";
import { handleError } from "../../../_helpers/errorHandler";
import {
  notificationAtom,
  notificationOptions,
} from "../../../_store/notification/store";
import { useRecoilState } from "recoil";
import Spinner from "../../../_components/Spinner";

const Analytics = () => {
  useMetaTags(defaultOptions.ANALYTICS);
  const [loading, setLoading] = useState(false);
  const [, setNotification] = useRecoilState(notificationAtom);

  const handleGetAnalytics = async () => {
    setLoading(true);
    try {
      const result = await getAnalytics();
      window.open(result.config.url, "Download");
    } catch (error) {
      setNotification({
        type: notificationOptions.ERROR,
        text:
          handleError(error) ||
          "Something went wrong, please try again in a moment",
        visible: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnalyticsWrapper>
      <Title>Analytics</Title>
      <AnalyticsFrame>
        <Label htmlFor="section">
          <FontAwesomeIcon className="label-icon" icon={faDatabase} />
          Click the button below to download the data
        </Label>

        <Button onClick={handleGetAnalytics}>
          {!loading ? "Get Analytics from Database" : <Spinner />}
        </Button>
      </AnalyticsFrame>
    </AnalyticsWrapper>
  );
};

export default Analytics;

const AnalyticsWrapper = styled.div`
  max-width: 100%;
  width: 1000px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: var(--page-title-font-size);
  text-align: center;
  color: var(--neutral-1000);
  margin-bottom: 3rem;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 2rem;
    font-size: 1.75rem;
  }
`;

const AnalyticsFrame = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--container-width);
  margin: 0 auto;
  margin-bottom: 3rem;
  padding: 2rem;
  background-color: var(--neutral-050);
  box-shadow: var(--box-shadow-default);
`;

const Label = styled.label`
  margin-bottom: 2rem;
  font-size: 1.15rem;
  font-weight: bold;

  .label-icon {
    margin-right: 0.75rem;
    font-size: 1.15rem;
  }

  @media screen and (max-width: 545px) and (orientation: portrait) {
    font-size: 1rem;
  }
`;

const Button = styled.button`
  padding: var(--xl-btn-padding);
  font-size: var(--lg-btn-font-size);
  outline: none;
  background-color: var(--primary-500);
  transition: all 0.2s ease;
  border: none;
  box-shadow: var(--box-shadow-default);
  color: var(--neutral-000);
  cursor: pointer;

  .spinner {
    margin: 0 !important;
  }

  &:hover {
    background-color: var(--primary-600);
  }
`;
