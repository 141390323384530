import React, { useState, useEffect, Suspense, lazy } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { animated } from "react-spring";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { useRecoilState } from "recoil";
import { sectionClassIdAtom } from "../../../_store/class/store";


const ClassResultTable = lazy(() => import("./ClassResultTable"));

function ClassResult() {
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const { classId } = useParams<{ classId: string }>();
  const [, setSectionClassId] = useRecoilState(sectionClassIdAtom);
  const pageTransition = useCustomTransition();
  

  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <ClassWrapper>
        <ContentWrapper>
          <Suspense
            fallback={
              <div className="fetching_info">Fetching class data...</div>
            }
          >
            <ClassResultTable classid={classId} />
          </Suspense>
        </ContentWrapper>
      </ClassWrapper>
    </animated.div>
  );
}

export default ClassResult;

const ClassWrapper = styled.div`
//   display: flex;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
  }
`;

const ContentWrapper = styled.div`
  overflow: scroll;

  .fetching_info {
    margin-top: 3rem;
    margin-left: 2rem;
  }
`;
