import { useSpring, config } from "react-spring";

function useCustomTransition() {
  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 500 },
    delay: 300,
  });
  return animationProps;
}

function useScaleTransition() {
  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: config.slow,
    delay: 500,
  });
  return animationProps;
}

export { useCustomTransition, useScaleTransition };
