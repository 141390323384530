import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./_styles/main.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { RecoilRoot } from "recoil";
import axios, { AxiosRequestConfig } from "axios";
import { getCookie } from "./_helpers/csrfCookie";

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers["X-CSRFToken"] = getCookie("csrftoken");
  config.withCredentials = true;
  return config;
});

const Root = () => (
  <Router>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Router>
);

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
