export const scenes = [
  "culture",
  "dialogue",
  "review",
  "dribblingrace",
  "rainingfootballs",
  "bubblepop",
  "fireworks",
  "handwriting",
  "blocksmash",
  "quiz",
];
