import { parseISO, isValid, format } from "date-fns/fp";

export const months = Array.from({ length: 12 }, (e, i) => {
  return new Date(0, i + 1, 0).toLocaleDateString("en", {
    month: "long",
  });
});

export const days = Array.from({ length: 31 }, (e, i) => {
  return new Date(0, 0, i + 1).toLocaleDateString("en", {
    day: "2-digit",
  });
});

export function range(
  size: number,
  startAt: number = 0,
  step: number = 0,
  year = false
): any {
  if (year) {
    return [...Array(size).keys()].map((_, index) =>
      (startAt + index).toString()
    );
  }
  if (step) {
    return [...Array(size).keys()].map((i) =>
      (i * step).toString().padStart(2, "0")
    );
  }
  return [...Array(size).keys()].map((i) =>
    (i + startAt).toString().padStart(2, "0")
  );
}

export const getISODate = (
  year: string,
  month: string,
  day: string,
  hour: string,
  minutes: string
) => {
  return new Date(
    Number(year),
    months.indexOf(month),
    Number(day),
    Number(hour),
    Number(minutes)
  ).toISOString();
};

export const getISODateFromDatePicker = (
  date: string,
  hour: string,
  minutes: string
) => {
  return new Date(`${date}T${hour}:${minutes}`).toISOString();
};

export const parseDateFromApi = (date: string) => {
  const parsedDate = new Date(Date.parse(date));
  const hours = parsedDate.getHours();
  const minutes = parsedDate.getMinutes();
  const day = parsedDate.getDay();
  const month = parsedDate.getMonth();
  const year = parsedDate.getFullYear();

  const format = (input: number) => input.toString().padStart(2, "0");

  const displayDate = `${format(day)}.${format(month)}.${year}`;
  const displayHour = `${format(hours)}:${format(minutes)}`;

  return [displayDate, displayHour];
};

export const parseDateFromApiForSelect = (date: string): string[] => {
  const parsedDate = parseISO(date);
  if (!isValid(parsedDate)) {
    return [defaultValue, defaultValue, ""];
  }

  const hours = format("HH", parsedDate);
  const minutes = format("mm", parsedDate);
  const dateFormatted = format("yyyy-MM-dd", parsedDate);

  return [hours, minutes, dateFormatted];
};

export const defaultValue: string = "---";

export const monthSet = [defaultValue, ...months];
export const daySet = [defaultValue, ...days];
export const yearSet = [defaultValue, ...range(9, 2020, 1, true)];
export const hourSet = [defaultValue, ...range(8, 8)];
export const minuteSet = [defaultValue, ...range(12, 0, 5)];

export const i18n = {
  week: "Week",
  calendar: "Calendar",
  clear: "Clear",
  today: "Today",
  cancel: "Cancel",
  firstDayOfWeek: 1,
  weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split(
    "_"
  ),
  monthNames: "January_February_March_April_May_June_July_August_September_October_November_December".split(
    "_"
  ),
  weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
  formatTitle: (monthName: any, fullYear: any) => `${monthName} ${fullYear}`,
  formatDate: (date: any) =>
    format("dd-MM-yyyy", new Date(date.year, date.month, date.day)),
};
