import axios, { AxiosResponse } from "axios";
import { endpoints } from "../_endpoints";
import { roots } from "../_urls";
import { useRecoilState } from "recoil";
import { loggedInUserAtom } from "../_store/auth/store";
import {
  notificationAtom,
  notificationOptions,
} from "../_store/notification/store";
import { useHistory } from "react-router-dom";
import { cleanCookie } from "../_helpers/csrfCookie";
import { useLocalStorage, LocalStorageKeys } from "../_helpers/localStorage";

export function useLogout() {
  const [, setUser] = useRecoilState(loggedInUserAtom);
  const [, setNotification] = useRecoilState(notificationAtom);
  const [, setLocalStorageUser] = useLocalStorage<any>(
    LocalStorageKeys.USER,
    ""
  );
  const history = useHistory();
  return () => {
    axios({
      method: "GET",
      url: endpoints.LOGOUT,
    })
      .then((response: AxiosResponse) => {
        setUser(null);
        setLocalStorageUser("");
        cleanCookie("csrftoken");
        history.push(roots.LOGIN);
        setNotification({
          type: notificationOptions.SUCCESS,
          text: "You have successfully logged out!",
          visible: true,
        });
      })
      .catch((error) => {
        history.push(roots.LOGIN);
      });
  };
}

export function useLogin(DATA: { username: string; password: string }) {
  const [, setUser] = useRecoilState(loggedInUserAtom);
  const [, setNotification] = useRecoilState(notificationAtom);
  const [, setLocalStorageUser] = useLocalStorage<any>(
    LocalStorageKeys.USER,
    ""
  );
  const history = useHistory();

  const bodyFormData = new FormData();
  bodyFormData.append("username", DATA.username);
  bodyFormData.append("password", DATA.password);

  return () => {
    axios({
      method: "POST",
      url: endpoints.LOGIN,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: AxiosResponse) => {
        setLocalStorageUser(response.data);
        setUser(response.data);
        setNotification({
          type: notificationOptions.SUCCESS,
          text: `Hi, ${
            response.data.userprofile.nickname ||
            response.data.first_name ||
            response.data.username
          }!`,
          visible: true,
        });

        return response.data;
      })
      .then((response) => {
        history.push(roots.DASHBOARD);
      })
      .catch((error) => {
        setNotification({
          type: notificationOptions.ERROR,
          text: error.response?.data || error?.response || "Login failed, please try again",
          visible: true,
        });
      });
  };
}
