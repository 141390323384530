import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import getScheduledLessonsList from "../../../_customHooks/getScheduledLessons";
import {
  notificationAtom,
  notificationOptions,
} from "../../../_store/notification/store";
import { sectionClassIdAtom } from "../../../_store/class/store";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { roots } from "../../../_urls";

function ScheduleModal() {
  const history = useHistory();
  const [modalVisibility, setModalVisibility] = useState(false);
  const hideModal = () => setModalVisibility(false);
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const { classId } = useParams<{ classId: string }>();
  const [scheduledLessonsList, setScheduledLessonsList] = useState([]);
  const [, setNotification] = useRecoilState(notificationAtom);
  const sectionClassId = useRecoilValue(sectionClassIdAtom);

  useEffect(() => {
    let unmounted = false;
    if (classId && sectionClassId) {
      const fetchScheduledLessons = async () => {
        try {
          const result = await getScheduledLessonsList(sectionClassId);
          if (!unmounted) {
            setScheduledLessonsList(result.data);
          }
        } catch (error) {
          setScheduledLessonsList([]);
          setNotification({
            type: notificationOptions.ERROR,
            text: "No schedule provided",
            visible: true,
          });
        }
      };
      if (!unmounted) {
        fetchScheduledLessons();
      }
    }
    return () => {
      unmounted = true;
    };
  }, [classId, setNotification, sectionClassId]);

  useEffect(() => {
    if (scheduledLessonsList) {
      if (scheduledLessonsList.length) {
        setModalVisibility(true);
      }
    }
  }, [scheduledLessonsList]);

  return (
    <Modal
      className={classNames({
        visible: modalVisibility,
        hidden: !modalVisibility,
      })}
    >
      <div className="modal-body">
        <p>
          Lesson schedule for this class already exists. If you use this
          functionality you will override the existing schedule.
        </p>
        <div className="buttons-div">
          <button
            className="default-button cancel"
            onClick={() =>
              history.push(`${roots.CLASS}/${classId}/lesson-schedule`)
            }
          >
            Cancel
          </button>

          <button className="default-button" onClick={hideModal}>
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ScheduleModal;

const Modal = styled.div`
  z-index: 3;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;

  p {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .modal-body {
    background-color: var(--neutral-000);
    text-align: center;
    margin: 0 auto;
    position: relative;
    padding: 2.5rem;
    outline: 0;
    width: 50rem;

    @media screen and (max-width: 998px) {
      max-width: 80vw;
    }
  }

  &.hidden {
    display: none;
  }

  &.visible {
    display: flex;
  }

  .buttons-div {
    display: flex;
    justify-content: center;
  }

  .default-button {
    padding: var(--xl-btn-padding);
    font-size: var(--lg-btn-font-size);
    outline: none;
    background-color: var(--primary-500);
    transition: all 0.2s ease;
    border: none;
    box-shadow: var(--box-shadow-default);
    color: var(--neutral-000);
    cursor: pointer;
    width: fit-content;
    margin: 1rem;

    &:hover {
      background-color: var(--primary-600);
    }

    &.cancel {
      background-color: var(--secondary-400);

      &:hover {
        background-color: var(--secondary-600);
      }
    }
  }
`;
