import React from "react";

import styled from "@emotion/styled";

function AdminBase({ children }: { children: any }) {
  return <Wrapper>{children}</Wrapper>;
}

export { AdminBase };

const Wrapper = styled.div`
  height: calc(100vh - (var(--header-height)));
  overflow: hidden;
  display: flex;
  position: relative;

  @media screen and (max-width: 545px) {
    overflow: initial;
  }
`;
