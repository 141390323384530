import { atom, selectorFamily } from "recoil";
import { gamesAtom } from "../game/store";
import { LessonProps } from "./models";

export const lessonsAtom = atom({
  key: "lessonsAtom",
  default: [] as LessonProps[],
});

export const currentLessonIndex = atom({
  key: "currentLessonAtom",
  default: 1 as number,
});

export const currentLessonDataAtom = selectorFamily({
  key: "currentLessonDataAtom",
  get: (index: number) => ({ get }) => {
    const lessons = get(gamesAtom);
    const currentLesson = lessons[index];

    return currentLesson;
  },
});

export const currentLessonProgressAtom = selectorFamily({
  key: "currentLessonProgressAtom",
  get: (id) => ({ get }) => {
    // const lessons = get(lessonsAtom);
    // const lesson = lessons.find((lesson) => lesson.lessonNumber === id);

    return 5;
  },
});

export const sideMenuOpenStateAtom = atom({
  key: "sideMenuOpenStateAtom",
  default: false,
});
