import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { format, isPast, parseISO } from "date-fns";
import { Link, useParams } from "react-router-dom";
import { roots } from "../../../_urls";
import { animated } from "react-spring";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";
import getScheduledLessonsList from "../../../_customHooks/getScheduledLessons";
import getClassId from "../../../_customHooks/getClassId";
import { useRecoilValue, useRecoilState } from "recoil";
import { loggedInUserAtom } from "../../../_store/auth/store"
import { isSchoolAdminRole, isWebAdminRole } from "../../../_helpers/theme";
import { ApiClient } from "../../../_axios";


type LessonScheduleRecord = {
  id: number;
  scheduled_lesson_class: number;
  lesson: number;
  datetime: string;
  lesson_title: string;
  lock_status: string;
};

const getDate = (datetime: string): string =>
  isPast(parseISO(datetime))
    ? "Lesson occurs in the past"
    : format(parseISO(datetime), "yyyy-MM-dd");

const getTime = (datetime: string): string =>
  format(parseISO(datetime), "HH:mm");

function LessonSchedule() {
  useMetaTags(defaultOptions.SCHEDULE);
  const user = useRecoilValue(loggedInUserAtom);
  const [scheduleData, setScheduleData] = useState([]);
  const [manualLockStatus, setManualLockStatus] = useState("");
  const [isManualLockStatus, setIsManualLockStatus] = useState(false);
  const [scheduleStatus, setScheduleStatus] = useState("");
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const { classId } = useParams<{ classId: string }>();
  const pageTransition = useCustomTransition();

  const fetchScheduledLessons = async () => {
    try {
      const resultClassId = await getClassId(Number(classId));
      const result_schedule = await getScheduledLessonsList(resultClassId.data.section_class);

      if (!result_schedule.data.length) {
        setScheduleStatus("The schedule is unavailable. Create one first");
        setManualLockStatus("Enable Manual Unlock");
        setIsManualLockStatus(false)

      } else {
        setScheduleStatus("");
        setScheduleData(result_schedule.data);
        if (result_schedule.data[0].lock_status.length){
          setManualLockStatus("Disable Manual Unlock");
          setIsManualLockStatus(!isManualLockStatus);
          setScheduleData([]);
        } else {
          setManualLockStatus("Enable Manual Unlock");
        }
      }
    } catch (error) {
      console.error(error);
      setScheduleData([]);
    }
  };

  useEffect(() => {
 
    fetchScheduledLessons();
  }, []);

  const manualUnlockHandler = async () => {
    setIsManualLockStatus(!isManualLockStatus);
    if (isManualLockStatus){
      setManualLockStatus("Enable Manual Unlock");
      const response = await ApiClient.enableOrDisableManualUnlock(Number(classId), 0);
      if (response.data.status == 202){
        await fetchScheduledLessons();
      }
      
    } else {
      setManualLockStatus("Disable Manual Unlock");
      const response = await ApiClient.enableOrDisableManualUnlock(Number(classId), 1);
      if (response.data.status == 200){
      setScheduleData([]);
      
      }
    }
  };
  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <LessonUnlockWrapper>
      <Title>Class schedule</Title>
        <ContentWrapper>
        {(isWebAdminRole(user) && manualLockStatus.length != 0) || (scheduleData.length > 0 && manualLockStatus.length == 0)? 
          (<button className="default-button class-link" onClick={manualUnlockHandler}>
           { manualLockStatus } 
          </button>): null
        }
          <p>Enabling Manual unlock disable the scheduling. In case you disable it you 
            need to again set the scheduling for the class</p>
          {!isManualLockStatus ?
          <> 
            <Calendar>
              {!scheduleData.length && !scheduleStatus && "Loading schedule..."}
              {scheduleStatus}

              {scheduleData &&
                scheduleData.map((lesson: LessonScheduleRecord, index: number) => {
                  return (
                    <LessonEvent key={index + 1}>
                      <LessonTitle>
                        {index + 1}. {lesson.lesson_title}
                      </LessonTitle>

                      <Time>
                        <FontAwesomeIcon className="schedule-icon" icon={faClock} />
                        {getTime(lesson.datetime)}
                      </Time>
                      <Date>
                        <FontAwesomeIcon className="schedule-icon" icon={faCalendar} />
                        {getDate(lesson.datetime)}
                      </Date>

                      <Link
                        className="class-link edit-link"
                        to={`${roots.CLASS}/${classId}/lesson-schedule/${lesson.id}`}
                      >
                        Edit
                      </Link>
                    </LessonEvent>
                  );
                })}
            </Calendar>
            {isWebAdminRole(user)? 
            (<Link className="default-button class-link" to={`${roots.CLASS}/${classId}/lesson-schedule/new`}>
              Manage Scheduled Lessons
              </Link>): null
              }
            </>: null
        }
        </ContentWrapper>
      </LessonUnlockWrapper>
    </animated.div>
  );
}

export default LessonSchedule;

const LessonUnlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    padding: 1rem 0;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .default-button {
    padding: var(--xl-btn-padding);
    font-size: var(--lg-btn-font-size);
    outline: none;
    background-color: var(--primary-500);
    transition: all 0.2s ease;
    border: none;
    box-shadow: var(--box-shadow-default);
    color: var(--neutral-000);
    cursor: pointer;
    width: fit-content;
    margin-top: 1rem;

    &:hover {
      background-color: var(--primary-600);
    }
  }

  .class-link {
    font-size: 1rem;
    color: var(--neutral-1000);
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  }
`;

const Title = styled.h3`
  margin-bottom: 1.8rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  height: 100%;
  width: 100%;
  padding-bottom: 10rem;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    align-items: center;
    justify-content: center;
  }
`;

const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 61.25rem;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    width: 100%;
    height: fit-content;
  }
`;

const LessonEvent = styled.div`
  background-color: var(--neutral-100);
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  &:nth-of-type(even) {
    background-color: var(--neutral-050);
  }

  @media screen and (max-width: 745px) and (orientation: portrait) {
    flex-direction: column;
    margin-bottom: 2rem;
    box-shadow: var(--box-shadow-default);
    width: 100%;
  }

  .date-time-wrapper {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: space-around;
  }

  .schedule-icon {
    margin-right: 0.3125rem;
  }

  .edit-link {
    padding: var(--xl-btn-padding);
    font-size: 1rem;
    outline: none;
    background-color: var(--primary-500);
    transition: all 0.2s ease;
    border: none;
    color: var(--neutral-000);
    cursor: pointer;

    @media screen and (max-width: 745px) and (orientation: portrait) {
      font-size: 1.3rem;
    }

    &:hover {
      background-color: var(--primary-600);
    }
  }
`;

const Time = styled.div`
  padding: 0;
  font-weight: bolder;
  color: var(--primary-600);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    width: 100%;
    padding: 1rem 0;
    font-size: 1.1rem;
  }
`;

const LessonTitle = styled.h5`
  margin: 0;
  font-size: 0.9rem;
  padding: 0.7rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 150%;
  height: 100%;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    width: 100%;
    padding: 1rem 0;
    text-align: center;
    justify-content: center;
    font-size: 1.1rem;
  }
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.9rem;
  color: var(--neutral-750);
  width: 30%;
  height: 100%;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    width: 100%;
    padding: 1rem 0;
    font-size: 1.1rem;
  }
`;
