import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {Link, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { roots } from "../../../_urls";
import {
  notificationAtom,
  notificationOptions,
} from "../../../_store/notification/store";
import { sectionClassIdAtom, } from "../../../_store/class/store";
import { endpoints } from "../../../_endpoints/";
import axios, { AxiosResponse } from "axios";
import { animated } from "react-spring";
import { useHistory } from "react-router-dom";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";
import { useRequest } from "../../../_customHooks/useRequest";
import { sectionUploadSpinner,} from "../../../_store/class/store";
import { handleError } from "../../../_helpers/errorHandler";
import {LessonToggleTile, LessonHeadTile} from "./LessonToggleTile";
import getClassId from "../../../_customHooks/getClassId";
import getScheduledLessonsList from "../../../_customHooks/getScheduledLessons";
import { isWebAdminRole } from "../../../_helpers/theme";
import { loggedInUserAtom } from "../../../_store/auth/store";

// type Lesson = {
//   id: number;
//   lesson_order: number;
//   title: string;
//   locked: boolean;
// };

type LessonScheduleRecord = {
  id: number;
  scheduled_lesson_class: number;
  lesson_order: number;
  datetime: string;
  lesson_title: string;
  lock_status: string;
  teachers_lock_status: any;
};

// type data = {unlocked_lesson?: string, manual_unlock_enabled?: boolean};


// function useSendLessonUnlock(classId: number | null, data: data) {
//   const [, setLoading] = useRecoilState(sectionUploadSpinner);
//   const [, setNotification] = useRecoilState(notificationAtom);

//   const urlValue = endpoints.GET_OR_UPDATE_CLASS(classId);
//   let request_data: data = {};

//   if(data.manual_unlock_enabled !== undefined){
//     request_data.manual_unlock_enabled = data.manual_unlock_enabled;
//   }
//   if(data.unlocked_lesson !== undefined){
//     request_data.unlocked_lesson = data.unlocked_lesson;
//   }

//   return () => {
//     setLoading(true);
//     axios({
//       method: "PUT",
//       url: urlValue,
//       data: data,
//     })
//       .then((response: AxiosResponse) => {
//         setNotification({
//           type: notificationOptions.SUCCESS,
//           text: "Changes saved",
//           visible: true,
//         });
//       })
//       .catch((error) => {
//         setNotification({
//           type: notificationOptions.ERROR,
//           text:
//             handleError(error) || "Something went wrong, check inputted data",
//           visible: true,
//         });
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
// }


function LessonUnlock() {
  useMetaTags(defaultOptions.LESSON_UNLOCK);
  const sectionClassId = useRecoilValue(sectionClassIdAtom);
  // const [, setNotification] = useRecoilState(notificationAtom);
  const user = useRecoilValue(loggedInUserAtom);
  const history = useHistory();
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const { classId } = useParams<{ classId: string }>();
  const [mLockEnabled, setMLockEnabled] = useState(false);

  if (sectionClassId === null) {
    history.push(`/class/${classId}`);
  }

  const pageTransition = useCustomTransition();
  // const [selectedLesson, setLesson] = useState<string>();

  const [scheduleData, setScheduleData] = useState([]);
  const [class_data]: any = useRequest(
    endpoints.GET_OR_UPDATE_CLASS(sectionClassId),
    "GET"
  );

  const fetchScheduledLessons = async () => {
    const resultClassId = await getClassId(Number(classId));
    const result_schedule = await getScheduledLessonsList(resultClassId.data.section_class);
    if (result_schedule.data.length) {
      setScheduleData(result_schedule.data);
      if (result_schedule.data[0].lock_status.length > 0){
        setMLockEnabled(true);
      }
    } else {
      console.log(result_schedule.data)
      // setNotification({
      //             type: notificationOptions.ERROR,
      //             text:"Something went wrong, check inputted data",
      //             visible: true,
      //           });
    }

  };
  useEffect(()=>{
    fetchScheduledLessons();
    // setLesson(class_data?.unlocked_lesson);
  }, [class_data])


  // const sendUnlockLessonRequest = useSendLessonUnlock(
  //   sectionClassId,
  //   {unlocked_lesson: selectedLesson}
  // );
  // const sendChangeUnlockModeRequest = useSendLessonUnlock(
  //   sectionClassId,
  //   {manual_unlock_enabled: !class_data?.manual_unlock_enabled}
  // );

  // const changeUnlockMode = () => {
  //   sendChangeUnlockModeRequest();
  // }
  // const sendUnlockLesson = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   sendUnlockLessonRequest();
  // };



  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <LessonUnlockWrapper>
        <Title>Lesson unlocking</Title>
        <ContentWrapper>
          {/* <Description>
            AUTO unlock mode is enabled. Lessons unlock according to the class
            schedule. If there is no schedule, the lessons remain unlocked.
            Enable manual unlock in order to override the class schedule.
          </Description> */}
          {
            !class_data?.manual_unlock_enabled || !mLockEnabled ?
            <Info>
              { isWebAdminRole(user)?
              <>
              To enable manual unlock go to <Link  to={`${roots.CLASS}/${classId}/lesson-schedule`}>
              lesson schedule
              </Link>
              </>: <>Lesson Unlock disabled:- <em>contact website admin</em> for <strong> unlock access</strong> or for create lessons scheduled.</>
            }
            
          </Info>: null
          }
         
          {/* {class_data?.manual_unlock_enabled ? null:
          <button className="default-button" onClick={changeUnlockMode}>Enable manual unlock */}
            {/* {class_data?.manual_unlock_enabled ? "Disable manual unlock" : "Enable manual unlock"} */}
          {/* </button>} */}
          {class_data?.manual_unlock_enabled && scheduleData && mLockEnabled && (

             <>
             {
              isWebAdminRole(user) && scheduleData.length > 0?
              <LessonHeadTile teachers_status={scheduleData[0]} />: null
             }
              {
                 scheduleData.map((lesson: LessonScheduleRecord, index: number) => <LessonToggleTile 
                 classid={parseInt(classId)} lessonNumber={lesson.lesson_order} teachers_lock_status={lesson.teachers_lock_status}/>)
              }
             </>

            // <Form onSubmit={sendUnlockLesson}>
            //   <select
            //     onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            //       setLesson(e.target.value)
            //     }
            //     value={selectedLesson}
            //   >
            //     <option key={"emptyOption"}></option>
            //     {lessonData.map((lesson: Lesson) => (
            //       <option value={lesson.id} key={lesson.id}>
            //         {lesson.title}
            //       </option>
            //     ))}
            //   </select>
            //   <input
            //     className="default-button"
            //     type="submit"
            //     value="Unlock Lessons"
            //   />
            // </Form>
          )}
        </ContentWrapper>
      </LessonUnlockWrapper>
    </animated.div>
  );
}

export default LessonUnlock;

const LessonUnlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  height: 100%;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    padding: 1rem;
  }
  .default-button {
    padding: var(--xl-btn-padding);
    font-size: var(--lg-btn-font-size);
    outline: none;
    background-color: var(--primary-500);
    transition: all 0.2s ease;
    border: none;
    box-shadow: var(--box-shadow-default);
    color: var(--neutral-000);
    cursor: pointer;
    width: fit-content;
    margin-top: 2rem;

    &:hover {
      background-color: var(--primary-600);
    }
  }
`;

const Title = styled.h3`
  margin-bottom: 1.8rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Info = styled.p`
  max-width: 50%;
  line-height: 150%;
  margin-top: 0;
  color: black;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    max-width: 100%;
  }
`;
const Description = styled.p`
  max-width: 50%;
  line-height: 150%;
  margin-top: 0;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    max-width: 100%;
  }
`;

const Warning = styled.p`
  max-width: 50%;
  line-height: 150%;
  margin-top: 0;
  color: red;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    max-width: 100%;
  }
`;


const Form = styled.form`
  display: flex;
  margin-top: 2rem;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    flex-direction: column-reverse;
  }

  select {
    padding: 0.3125rem 0.625rem;
    border: 1px solid var(--neutral-100);
    border-radius: 0;
    outline: none;
    margin-right: 1rem;
    margin-top: 2rem;
    background: var(--neutral-050);
    height: 2.5rem;
  }
`;
