import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  notificationAtom,
  notificationOptions,
} from "../../../_store/notification/store";
import { useRecoilState, useRecoilValue } from "recoil";
import { isTeacherRole, isWebAdminRole } from "../../../_helpers/theme";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loggedInUserAtom } from "../../../_store/auth/store";
import { ApiClient } from "../../../_axios";


const LessonHeadTile = ({ teachers_status}: 
    { 
        teachers_status: any;
    }) => {

    const teachers = Object.keys(teachers_status.teachers_lock_status);

    return  (
        <LessonWrapper >

        { teachers.length > 2?
            <>
            <HeadTile >
            <div className="px-2">Teacher: {teachers[0]}</div>

            </HeadTile>
            <HeadTile >
                <div className="px-2">Teacher: {teachers[1]}</div>
            
            </HeadTile>
            </>: <HeadTile >
                <div className="px-2">Teacher: {teachers[0]} </div>

            </HeadTile>}
        </LessonWrapper>
    );
}

const LessonToggleTile = ({ classid, lessonNumber, teachers_lock_status}: 
    { 
        classid: number; 
        lessonNumber: number; 
        teachers_lock_status: any;
    }) => {

    const [lockState, setLockState] = useState(teachers_lock_status.all);
    const [, setNotification] = useRecoilState(notificationAtom);
    const teachers = Object.keys(teachers_lock_status);
    console.log(teachers, teachers_lock_status)
    const user = useRecoilValue(loggedInUserAtom);
    const [lockChange, setLockChange] = useState("no");

    const toggleHandler = async() => {
        setLockState(!lockState);
        if (lockState){
            const response = await ApiClient.LockOrUnlockLesson(Number(classid), Number(lessonNumber), 0);
            if (response.data.status == 403){
            // setLockState(!lockState);
                setNotification({
                    type: notificationOptions.ERROR,
                    text:"Session expired.",
                    visible: true,
                });
                window.location.replace(window.location.origin);
            } else if (response.data.status >= 500) {
                setNotification({
                    type: notificationOptions.ERROR,
                    text:"Session expired.",
                    visible: true,
                  });
            } else {
                setLockChange("");
            }
        } else {
            const response = await ApiClient.LockOrUnlockLesson(Number(classid), Number(lessonNumber), 1);
            if (response.data.status == 403){
                // setLockState(!lockState);
                setNotification({
                    type: notificationOptions.ERROR,
                    text:"Session expired.",
                    visible: true,
                  });
                  window.location.replace(window.location.origin);
                
            } else if (response.data.status >= 500) {
                setNotification({
                    type: notificationOptions.ERROR,
                    text:"Something went wrong.",
                    visible: true,
                  });
                  
            } else {
                setLockChange("");
            }
        }
        console.log( lessonNumber, lockState);
    };

    // useEffect(() => {
        
    // }, [lockChange, setLockChange]);

    const teacherToLockStatus = (indx: number): boolean => {
        return teachers_lock_status[teachers[indx]];
    };

    return (
        
        <LessonWrapper >
            { isWebAdminRole(user) ?
                <>
                { teachers.length > 2?
                    <>
                        <LessonTile  bgcolor={lockChange.length ?teacherToLockStatus(0):lockState } >
                            <div className="px-2">Lesson {lessonNumber}</div>
                            <div className="section-card-icon">
                                {/* <i className="fa fa-arrow-circle-right"></i> */}
                                <FontAwesomeIcon  icon={faArrowCircleRight} />
                            </div>
                        </LessonTile>
                        <LessonTile  bgcolor={lockChange.length ?teacherToLockStatus(1):lockState }>
                            <div className="px-2">Lesson {lessonNumber}</div>
                            <div className="section-card-icon">
                            <FontAwesomeIcon  icon={faArrowCircleRight} />
                            </div>
                        </LessonTile>
                    </>:  
                    <LessonTile bgcolor={lockChange.length ?teacherToLockStatus(0):lockState }>
                    <div className="px-2">Lesson {lessonNumber}</div>
                    <div className="section-card-icon" >
                    <FontAwesomeIcon  icon={faArrowCircleRight} />
                    </div>
                </LessonTile>
                }
                </>: null
                }
            {isTeacherRole(user)?
            <LessonTile bgcolor={lockChange.length ?teacherToLockStatus(0):lockState }>
                <div className="px-2">Lesson {lessonNumber}</div>
                <div className="section-card-icon" >
                <FontAwesomeIcon  icon={faArrowCircleRight} />
                </div>
            </LessonTile>: null}

            <SwitchWrapper>
                <label className="switch">
                    {lockState? 
                    <input className="switch-input" type="checkbox"
                        data-info={classid} onClick={toggleHandler} >
                    </input>:
                    <input  className="switch-input" type="checkbox"
                        data-info={classid} onClick={toggleHandler} checked>
                    </input>}
                <span className="switch-slider round"></span>
                </label>
            </SwitchWrapper>
        </LessonWrapper>
    );

};

export {LessonHeadTile,  LessonToggleTile};



const LessonWrapper = styled.div`
  display: flex;
  width: 35%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
`;

const LessonTile = styled.div`
    display: flex;
    background: ${
        (props:any) => props.bgcolor? "linear-gradient(90deg, rgb(151 147 147) 39%, rgb(209 209 209) 100%)":
        "linear-gradient(90deg, rgba(129,21,21,1) 36%, rgb(231 43 43) 100%)"};
    width: 100%;
    padding: 1.27em;
    border-radius: 50px;
    justify-content: space-between;
    margin-right: 7px;
    color: white;
    box-shadow: 0px 1px 5px 1px rgb(73, 73, 73);

`;

const HeadTile = styled.div`
    display: flex;
    background: #fff;
    width: 100%;
    padding: 1.27em;
    justify-content: space-between;
    margin-right: 7px;
    color: black;
    font-weight: bold;

`;
const SwitchWrapper = styled.div`
    padding: 3px;

`;