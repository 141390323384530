import React from "react";
import styled from "@emotion/styled";

const ProgressBar: React.FC<{ progress: number }> = ({
  progress,
}): JSX.Element => {
  return (
    <ProgressIndicator className="progress-bar" lessonProgress={progress} />
  );
};

export default ProgressBar;

type lessonProgressProps = {
  lessonProgress: number;
};

const ProgressIndicator = styled.div<lessonProgressProps>`
  background-color: ${(props) => `${props.lessonProgress === 999 ? "#adadad": "var(--neutral-000)"}`};
  border-radius: var(--border-radius);
  border: 1px solid #49723b;
  height: 1.5625rem;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: ${(props) => `${props.lessonProgress === 999 ? "\"Locked\"": "\"Lesson Progress\""}`};
    color: ${(props) => `${props.lessonProgress === 999 ? "#606060": "var(--neutral-1000)"}`};
    font-family: var(--base-font-family);
    z-index: 2;
    font-size: 1.2rem;
    text-align: center;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: transparent;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &:after {
    content: "";
    color: var(--neutral-1000);
    position: absolute;
    border-radius: var(--border-radius);
    background-color: var(--progress-color);
    border: 0px solid #49723b;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${(props) => `${props.lessonProgress !== 999 ? props.lessonProgress: 0}%`};
    min-width: 0%;
    max-width: 100%;
  }

  @media screen and (max-width: 545px) and (orientation: portrait) {
    height: 1.875rem;
    font-weight: bold;
  }
`;
