import React, { useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { roots } from "../_urls";
import { roles } from "../_helpers/roles";
import PrivateRoute from "../_components/PrivateRoute";
import Navbar from "../_components/Navigation/Navbar";
import Login from "../pages/CommonPages/Login/Login";
import Lesson from "../pages/CommonPages/Lesson/Lesson";
import Lessons from "../pages/CommonPages/Lessons/Lessons";
import Badges from "../pages/CommonPages/Badges/Badges";
import Class from "../pages/AdminPages/Class/Class";
import LessonUnlock from "../pages/AdminPages/Class/LessonUnlock";
import LessonSchedule from "../pages/AdminPages/Class/LessonSchedule";
import ClassResult from "../pages/AdminPages/Class/ClassResult";
import SchoolResult from "../pages/AdminPages/Class/SchoolResult";
import EditRow from "../pages/AdminPages/Class/EditRow";
import Courses from "../pages/AdminPages/Courses/Courses";
import Student from "../pages/AdminPages/Student/Student";
import NotFound from "../pages/CommonPages/404/404";
import { useRecoilValue, useRecoilState } from "recoil";
import { loggedInUserAtom } from "../_store/auth/store";
import Notification from "../_components/Notification";
import { notificationAtom } from "../_store/notification/store";

import { attachDashboardToRole, webAdminPermissionCheck } from "./_helpers";
import ClassCreation from "../pages/AdminPages/ClassCreation/ClassCreation";
import Analytics from "../pages/AdminPages/Analytics/Analytics";
import ForceStudentProgress from "../pages/AdminPages/Class/ForceStudentProgress";
import { useLocalStorage, LocalStorageKeys } from "../_helpers/localStorage";
import ClassSidebar from "../pages/AdminPages/Class/ClassSidebar";
import ScheduleCreation from "../pages/AdminPages/Class/ScheduleCreation";
import useSetTheme from "../_customHooks/useSetTheme";
import { AdminBase } from "./AdminBase";
import Calendar from "../pages/AdminPages/Calendar";
import { GuideDocs, TeachingSlides } from "../pages/AdminPages/TeachingSlidesAndGuideDocs";

function App() {
  const [user, setUser] = useRecoilState(loggedInUserAtom);
  const showNotification = useRecoilValue(notificationAtom).visible;
  const [localStorageUser] = useLocalStorage<any>(LocalStorageKeys.USER, "");
  const location = useLocation();
  useSetTheme(user, location);

  useEffect(() => {
    localStorageUser && setUser(localStorageUser);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!user && !localStorageUser && location.pathname !== roots.LOGIN ? (
        <Redirect to={{ pathname: roots.LOGIN }} />
      ) : (
        <div>
          {location.pathname !== roots.LOGIN && <Navbar />}
          {showNotification ? <Notification /> : null}
          <Switch>
            <Route exact path={roots.LOGIN} component={Login} />
            <PrivateRoute exact={false} path={`${roots.LESSON}/:lessonNumber`} component={Lesson} />
            <PrivateRoute exact path={roots.LESSONS} component={Lessons} />
            <PrivateRoute exact path={roots.BADGES} component={Badges} />
            <PrivateRoute exact path={roots.CALENDAR} component={Calendar} />
            <PrivateRoute exact path={roots.DASHBOARD} component={attachDashboardToRole(user)} />
            <PrivateRoute
              exact={false}
              path={`${roots.CLASS}/:classId`}
              component={() => (
                <AdminBase>
                  <ClassSidebar />
                  <Switch>
                    <PrivateRoute exact path={`${roots.CLASS}/:classId`} component={Class} />
                    <PrivateRoute exact path={`${roots.CLASS}/:classId/result`} component={ClassResult} />
                    <PrivateRoute exact path={`${roots.CLASS}/:classId/school/result`} component={SchoolResult} />
                    <PrivateRoute exact path={`${roots.CLASS}/:classId/lesson-unlock`} component={LessonUnlock} />
                    <PrivateRoute
                      exact
                      path={`${roots.CLASS}/:classId/${roots.FORCE_PROGRESS}`}
                      component={ForceStudentProgress}
                    />
                    <PrivateRoute exact path={`${roots.CLASS}/:classId/lesson-schedule`} component={LessonSchedule} />
                    <PrivateRoute
                      exact
                      path={`${roots.CLASS}/:classId/lesson-schedule/new`}
                      component={ScheduleCreation}
                    />
                    <PrivateRoute exact path={`${roots.CLASS}/:classId/lesson-schedule/:rowId`} component={EditRow} />
                  </Switch>
                </AdminBase>
              )}
            />

            <PrivateRoute exact path={roots.CLASS_CREATION} component={webAdminPermissionCheck(user, ClassCreation)} />
            <PrivateRoute exact path={roots.TEACHING_SLIDES} component={TeachingSlides} />
            <PrivateRoute exact path={roots.GUIDE_DOCS} component={GuideDocs} />
            <PrivateRoute exact path={roots.ANALYTICS} component={webAdminPermissionCheck(user, Analytics)} />
            <PrivateRoute
              exact
              path={roots.COURSES}
              component={user?.userprofile?.role === roles.STUDENT ? NotFound : Courses}
            />

            <PrivateRoute
              exact
              path={`${roots.STUDENT}/:userId`}
              component={user?.userprofile?.role === roles.STUDENT ? NotFound : Student}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      )}
    </>
  );
}

export default App;
