import axios, { AxiosResponse } from "axios";
import { endpoints } from "../_endpoints";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationOptions,
} from "../_store/notification/store";
import { sectionUploadSpinner } from "../_store/class/store";
import { handleError } from "../_helpers/errorHandler";

type RecordType = {
  scheduled_lesson_class?: number;
  lesson?: number;
  datetime?: string;
};

export enum scheduleOption {
  WEEKLY = "weekly",
  CUSTOM = "custom",
}

export function useSendSchedule(
  scheduleRecords: RecordType | RecordType[],
  scheduleType: string = scheduleOption.WEEKLY
) {
  const [, setNotification] = useRecoilState(notificationAtom);
  const [, setLoading] = useRecoilState(sectionUploadSpinner);
  const urlValue =
    scheduleType === scheduleOption.WEEKLY
      ? endpoints.WEEKLY_SCHEDULE
      : endpoints.SCHEDULE;

  const successMessage =
    scheduleType === scheduleOption.WEEKLY
      ? "Weekly class schedule created with success!"
      : "Custom class schedule created with success!";

  return () => {
    setLoading(true);
    axios({
      method: "POST",
      url: urlValue,
      data: scheduleRecords,
    })
      .then((response: AxiosResponse) => {
        setNotification({
          type: notificationOptions.SUCCESS,
          text: successMessage,
          visible: true,
        });
      })
      .catch((error) => {
        setNotification({
          type: notificationOptions.ERROR,
          text:
            handleError(error) || "Something went wrong, check inputted data",
          visible: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
}

export default useSendSchedule;
