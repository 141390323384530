import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Hamburger from "./Hamburger";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../_store/auth/store";
import logo from "../../_assets/images/logo/logo.png";
import { isStudentRole } from "../../_helpers/theme";
import { useLocation, useHistory } from "react-router-dom";
import { roots } from "../../_urls";

const Navbar = () => {
  const user = useRecoilValue(loggedInUserAtom);
  const classTheme = isStudentRole(user) ? "" : "admin-styling";
  const location = useLocation().pathname;
  const history = useHistory();

  return (
    <Nav
      className={classTheme}
      lessonView={location === roots.LESSON || location === roots.LESSONS}
    >
      <Logo className={classTheme} onClick={() => history.push("/")}>
        <img src={logo} alt="arabicconnect logo" className="logo" />
      </Logo>
      <Hamburger />
    </Nav>
  );
};

export default Navbar;

const Nav = styled.nav<{ lessonView: boolean }>`
  height: var(--header-height);
  width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 19;
  padding: 0;
  background-color: transparent;
  background-color: var(--body-background-color);
  border-bottom: 0.5rem dashed var(--primary-500);

  &.admin-styling {
    border-bottom: none;
    background-color: var(--neutral-1000);

    ${({ lessonView }) =>
      lessonView &&
      css`
        background-color: var(--body-background-color);
        border-bottom: 0.5rem dashed var(--primary-500);
      `}
  }

  @media screen and (max-width: 545px) and (orientation: portrait) {
    padding: 0;
  }
`;

const Logo = styled.div`
  cursor: pointer;
  height: 100%;
  width: 15.625rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.25rem;
  color: var(--neutral-000);
  background-position: 0% 50%;
  background-size: 100% 70%;
  background-repeat: no-repeat;
  padding: 0.3125rem;
  z-index: 25;

  &.admin-styling {
    background-position: 50% 45%;
    width: 10.75rem;

    .logo {
      height: 2.5rem;
    }
  }

  .logo {
    width: auto;
    max-width: 100%;
    border: 1px solid #49723b;
    border-radius: 1.1rem;
  }
`;
