import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Title } from "../../../_components/common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../../_store/auth/store";
import { base } from "../../..//_endpoints/index";
import { animated } from "react-spring";
import { useCustomTransition } from "../../../_customHooks/pageTransition";

const badgeWidth =
  parseInt(getComputedStyle(document.body).getPropertyValue("--badge-width")) *
  16;

function useAutofillGridCount(): [number, React.RefObject<HTMLDivElement>] {
  const listRef = useRef<HTMLDivElement>(null);
  const [cols, setCols] = useState(4);

  useEffect(() => {
    const listener = () => {
      setCols(Math.floor(listRef.current!.offsetWidth / badgeWidth));
    };

    listener();

    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  }, []);

  return [cols, listRef];
}

function Badges() {
  const pageTransition = useCustomTransition();
  const isSideEdge = (index: number, cols: number) => (index + 1) % cols === 0;
  const isBottomEdge = (index: number, length: number, cols: number) =>
    Math.ceil(length / cols) * cols - (index + 1) < cols;
  const [cols, listRef] = useAutofillGridCount();

  const user = useRecoilValue(loggedInUserAtom);
  const badges = user?.userprogress?.badges;

  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <Title>Badges</Title>
      <BadgesList ref={listRef}>
        {!badges ? (
          <div className="no-data-wrapper">
            <p>no data</p>
          </div>
        ) : (
          badges.map((badge, i) => {
            return (
              <Badge
                key={i}
                className={classNames({
                  "side-edge-item": isSideEdge(i, cols),
                  "bottom-edge-item": isBottomEdge(i, badges.length, cols),
                })}
              >
                <BadgeWrapper>
                  <BadgePicture
                    className={classNames({ inactive: !badge.earned })}
                    src={`${base}${badge.image}`}
                  />
                </BadgeWrapper>
                <BadgeDescription>
                  <BadgeName>{badge.name}</BadgeName>
                  <BadgeFor>{badge.description}</BadgeFor>
                </BadgeDescription>
              </Badge>
            );
          })
        )}
      </BadgesList>
    </animated.div>
  );
}

export default Badges;

const BadgesList = styled.div`
  max-width: var(--container-width);
  margin: 2rem auto 3rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--badge-width));
  align-items: center;
  text-align: center;
  border-radius: var(--border-radius);
  justify-content: center;

  .side-edge-item {
    border-right: none;
  }

  .bottom-edge-item {
    border-bottom: none;
  }
`;

const Badge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.3rem;
  max-width: var(--badge-width);
  height: 19rem;
  border-bottom: 0.3rem dashed var(--border-color);
  border-right: 0.3rem dashed var(--border-color);
`;

const BadgeWrapper = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
`;

const BadgePicture = styled.img`
  width: 5rem;
  padding: 0;

  &.inactive {
    filter: grayscale(100%) opacity(70%);
  }
`;

const BadgeDescription = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const BadgeName = styled.h2`
  font-family: var(--base-font-family);
  color: var(--neutral-1000);
  margin: 0;
  height: 67px;
`;

const BadgeFor = styled.p`
  color: #707070;
  margin: 0;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
`;
