import axios from "axios";
import { endpoints } from "../_endpoints";

export function getLessons() {
  return axios({
    method: "GET",
    url: `${endpoints.LESSONS}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default getLessons;
