const ENDPOINTS = {
  MISSIN_COINS_GAME: `/api/lessons/get_random_unfinished_slide`,
  LOGIN: `/api/users/login/`,
  LOGOUT: `/api/users/logout/`,
  CREATE_CLASS: `/api/users/class/`,
  SECTIONS: `/api/users/section/`,
  SCHEDULE: `/api/lessons/scheduled_lesson/`,
  MANUAL_ENBLE_OR_DISABLE: `/api/lessons/enable_manual_unlock/`,
  UNLOCK_LESSON: `/api/lessons/lock_unlock/`,
  WEEKLY_SCHEDULE: `/api/lessons/create_weekly_schedule/`,
  LESSONS: `/api/lessons/lesson/`,
  LESSON: (lessonId: string): string => `/api/lessons/lesson/${lessonId}/`,
  SCHEDULED_LESSON: (rowId: number): string => `/api/lessons/scheduled_lesson/${rowId}/`,
  USER_PROGRESS: (userId: string): string => `/api/users/user/${userId}/`,
  USER_STATISTICS: (userId: string): string => `/api/users/user/${userId}/statistics`,
  GET_OR_UPDATE_CLASS: (classId: any): string => `/api/users/class/${classId}`,
  SLIDE: `/api/lessons/slide/`,
  CALENDAR_TEACHER: (path: string): any => `${path}/api/lessons/scheduled_meetings/`,
  GAME_EVENT: `/api/events/event/`,
  USER: `/api/users/user/`,
  ANALYTICS: `/api/users/analytics`,
  GET_CALENDAR_BY_MONTH_AND_YEAR: (month: number, year: number): string =>
    `/api/lessons/scheduled_meetings/?month=${month}&year=${year}`,
  EVENT_COMPLETE: (slide: number): string => `/api/events/event/?slide=${slide}&action=Completed`,
  FORCE_PROGRESS: (classId: string): string => `/api/users/class/${classId}/switch_force_progress`,
};

export default ENDPOINTS;
