import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../../_store/auth/store";
import { currentLessonIndex } from "../../../_store/lessons/store";
import { LessonProps } from "../../../_store/lessons/models";
import coin from "../../../_assets/images/navigation/coin.png";
import ProgressBar from "../../../_components/ProgressBar";
import { useHistory } from "react-router-dom";
import { roots } from "../../../_urls";
import { endpoints } from "../../../_endpoints";
import { useRequest } from "../../../_customHooks/useRequest";
import {
  useCustomTransition,
  useScaleTransition,
} from "../../../_customHooks/pageTransition";
import { lessonsAtom } from "../../../_store/lessons/store";
import { animated } from "react-spring";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";
import classNames from "classnames";
import { roles } from "../../../_helpers/roles";

function Lessons() {
  const [lessonData]: any = useRequest(endpoints.LESSONS, "GET");
  const user = useRecoilValue(loggedInUserAtom);
  const isStudent = user?.userprofile.role === roles.STUDENT;
  const [, selectLesson] = useRecoilState(currentLessonIndex);
  const [, setLessonData] = useRecoilState<LessonProps[]>(lessonsAtom);
  const history = useHistory();
  const pageTransition = useCustomTransition();
  const scaleTransition = useScaleTransition();
  useMetaTags(defaultOptions.LESSONS);

  useEffect(() => {
    lessonData && setLessonData(lessonData);
  }, [lessonData, setLessonData]);

  const navigateToLesson = (id: number) => {
    selectLesson(id);
    history.push(`${roots.LESSON}/${id}/slide/1`);
  };

  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <LessonsContainer>
        <animated.div style={scaleTransition}>
          <Title>Introduction to Arabic</Title>
        </animated.div>
        <LessonsWrapper lessonCount={lessonData?.length}>
          {lessonData &&
            lessonData.map((lesson: LessonProps, i: number) => {
              return (
                <div
                  key={i}
                  style={scaleTransition}
                  className={classNames(
                    "dotted-border-style single-lesson-wrapper",
                    { unlocked: !isStudent ? true : lesson.unlocked }
                  )}
                >
                  <div className="dotted-border-bottom" />
                  <Description>
                    <LessonTitle>
                      Lesson {i + 1}: {lesson.title}
                    </LessonTitle>
                    {/* <LessonDescription>{lesson.description}</LessonDescription> */}
                    <ImageWrapper imgSrc={lesson.image} />
                    <LessonProgress>
                      <CoinsInfo>
                        <Button
                          lessonUnlocked={isStudent ? lesson.unlocked : true}
                          onClick={() =>
                            isStudent
                              ? lesson.unlocked &&
                                navigateToLesson(lesson.lesson_order)
                              : navigateToLesson(lesson.lesson_order)
                          }
                        >
                          {!isStudent
                            ? "Start Lesson"
                            : lesson.unlocked
                            ? "Start Lesson"
                            : "Lesson locked"}
                        </Button>

                        <div className="progress-indicator">
                          <img src={coin} alt="" className="bull-icon" />
                          <p>
                            x {lesson.current_user_coins || 0} /{" "}
                            {lesson.max_coins}
                          </p>
                        </div>
                      </CoinsInfo>
                      <ProgressBar
                        progress={(lesson.current_user_precentage_progress >= 0 && lesson.unlocked) ? lesson.current_user_precentage_progress: 999}
                      />
                    </LessonProgress>
                  </Description>
                </div>
              );
            })}
        </LessonsWrapper>
      </LessonsContainer>
    </animated.div>
  );
}

export default Lessons;

const LessonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  font-size: var(--student-page-title-font-size);
  font-family: var(--base-font-family);
  text-align: center;
  color: var(--secondary-500);
  margin-bottom: 1.75rem;
  margin-top: 2rem;
  font-weight: normal;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    padding: 0 0.9375rem;
    font-size: 2.5rem;
    max-width: 100%;
  }
`;

const LessonsWrapper = styled.div<{ lessonCount: number }>`
  display: flex;
  flex-wrap: wrap;
  max-width: 1250px;
  justify-content: center;
  max-width: var(--lessons-container-width);
  margin-top: 1.5rem;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    max-width: 100%;
    min-width: initial;
    margin-top: 1.25rem;
  }

  .single-lesson-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 32rem;
    margin: 0 1rem 3.125rem;
    border: 0.625rem dashed var(--primary-500);
    border-radius: var(--border-radius);
    background-color: var(--cards-background-color);
    position: relative;
    overflow: hidden;

    &:not(.unlocked) {
      filter: brightness(0.5);
    }

    @media screen and (max-width: 545px) and (orientation: portrait) {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      height: auto;
      align-items: center;
      padding: 0.9375rem 0;
    }
  }
`;

const ImageWrapper = styled.div<{ imgSrc: string }>`
  display: block;
  height: 10.625rem;
  margin-bottom: 1.25rem;
  width: var(--single-lesson-image-width);
  overflow: hidden;
  background-image: ${({ imgSrc }) => `url(${imgSrc})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // border-radius: 0.3125rem;
  background-position: center;
  margin-bottom: 0;
  min-width: 70%;
  width: 15rem;
  box-shadow: 0px 3px 3px #707070;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    height: 12.5rem;
    margin-bottom: 1.25rem;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1 0 auto;
  padding: 1.875rem;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const LessonTitle = styled.h1`
  margin: 0;
  text-align: center;
  color: var(--neutral-1000);
  font-family: var(--base-font-family);
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

// const LessonDescription = styled.p`
//   font-family: var(--base-font-family);
//   color: var(--neutral-1000);
//   font-size: 1.15rem;
//   text-align: center;
//   line-height: 150%;
//   margin: 1.25rem auto;
//   padding: 0 0.9375rem;
//   width: 100%;
//   flex: 1 0 auto;

//   @media screen and (max-width: 545px) and (orientation: portrait) {
//     margin: 40px auto;
//     font-size: 1.25rem;
//     max-width: 95%;
//   }
// `;

const LessonProgress = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CoinsInfo = styled.div`
  display: flex;
  justify-content: stretch;
  margin-bottom: 1.175rem;
  // margin-top: 1.25rem;

  .progress-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 50%;

    p {
      font-family: var(--base-font-family);
      font-weight: bold;
      font-size: 1.7rem;
      margin-bottom: 0;
    }
  }

  .bull-icon {
    width: 5.125rem;
    // margin-right: 0.9375rem;
  }

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.9375rem;
  }
`;

const Button = styled.button<{ lessonUnlocked: boolean }>`
  border: none;
  // padding: var(--xl-btn-padding);
  color: var(--neutral-000);
  // font-size: var(--lg-btn-font-size);
  font-size: 1.7rem;
  font-weight: bold;
  font-family: var(--base-font-family);
  cursor: ${({ lessonUnlocked }) => (lessonUnlocked ? "pointer" : "no-drop")};
  background: var(--primary-500);
  outline: none;
  border-radius: var(--border-radius);
  width: 50%;
  height: 3.5rem;
  margin-top: 1.5rem;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    margin-bottom: 1.25rem;
    width: 100%;
  }
`;
