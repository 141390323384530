/* eslint-disable */
import React, { useEffect, useRef, useState, useCallback, RefObject } from "react";
import styled from "@emotion/styled";
import { useRecoilValue, useRecoilState } from "recoil";
import { currentLessonIndex, lessonsAtom, sideMenuOpenStateAtom } from "../../../_store/lessons/store";
import getSingleSlide from "../../../_customHooks/getSingleSlide";
import { useParams, useHistory } from "react-router-dom";
import { LessonProps } from "../../../_store/lessons/models";
import sendGameEvent from "../../../_customHooks/sendGameEvent";
import { scenes } from "./ScenesData";
import { roots } from "../../../_urls";
import { notificationAtom, notificationOptions } from "../../../_store/notification/store";
import { base, bucket } from "../../../_endpoints/index";
import checkIfSlideCompleted from "../../../_customHooks/getEventCompleted";
import GameNav from "../../../_components/Navigation/Gamenav";
import { useLocalStorage, LocalStorageKeys } from "../../../_helpers/localStorage";
import { loggedInUserAtom } from "../../../_store/auth/store";
import axios from "axios";
import { endpoints } from "../../../_endpoints";

type LessonDataProps = {
  lesson: number;
  gameNumber: string;
  top_score: number;
  max_coins: number;
  coins_earned: number;
  image: string | null;
  scene_name: string;
  next_lesson_available: number;
  coin_list: [];
  lessonData: any;
  baseLink: string;
  bucketLink: string;
};

type CheckEventSendDataProps = {
  isCompleted: boolean;
  maxScore: number;
}

function SingleGame() {
  let { gameNumber } = useParams<{ gameNumber: string }>();
  const [postSent, setPostAsSent] = useState(false);
  const lessonNumber = useRecoilValue(currentLessonIndex);
  const [gameData, setGameData] = useState<null | any>(null);
  const [shouldMount, setShouldMount] = useState(false);
  const lessonData = useRecoilValue(lessonsAtom);
  const [lessonVideo, setLessonVideo] = useState<string | undefined>(undefined);
  const [, setNotification] = useRecoilState(notificationAtom);
  const iFrameRef = useRef() as any;
  const gameSlideId = lessonData.find((lesson: LessonProps) => lesson.lesson_order === lessonNumber)?.slides[
    +gameNumber - 1
  ];
  const history = useHistory();
  const isVideoPlayback = +gameNumber === 2;
  const videoPlaybackRef = useRef() as RefObject<HTMLVideoElement>;
  const [menuOpenState, setMenuOpenState] = useRecoilState(sideMenuOpenStateAtom);
  const [user, setUser] = useRecoilState(loggedInUserAtom);
  const [, setLocalStorageUser] = useLocalStorage<any>(LocalStorageKeys.USER, "");
  const [localStorageGame, setLocalStorageGame] = useLocalStorage<any>(LocalStorageKeys.GAME, "");
  const [lessonDataObject, setLessonDataObject] = useState<LessonDataProps | {}>({});

  const getGameData = useCallback(async () => {
    setShouldMount(false);
    setPostAsSent(false);
    try {
      const singleSlideData = await getSingleSlide(gameSlideId.id);
      singleSlideData.data.entries[0]?.video && setLessonVideo(singleSlideData.data.entries[0].video);

      setLocalStorageGame(singleSlideData.data);
      setShouldMount(true);
    } catch (err) {
      if (Number(gameNumber) === 10) {
        history.push(roots.LESSONS);
        setNotification({
          type: notificationOptions.ERROR,
          text: "This lesson is locked",
          visible: true,
        });
      } else {
        history.go(-1);
      }
    }
  }, [gameSlideId, gameNumber, history, setNotification]);

  useEffect(() => {
    setLessonDataObject({
      lesson: lessonNumber,
      gameNumber,
      top_score: localStorageGame?.coins_earned,
      max_coins: localStorageGame?.max_coins,
      coins_earned: localStorageGame?.coins_earned,
      image: localStorageGame ? localStorageGame?.image : null,
      scene_name: scenes[+gameNumber - 1],
      next_lesson_available: 1,
      coin_list: [],
      lessonData: localStorageGame ? localStorageGame?.entries : [],
      baseLink: base,
      bucketLink: bucket,
    });
    setGameData(localStorageGame);
  }, [localStorageGame, gameNumber, gameSlideId]);

  const checkEventAndSendDataToIframe = useCallback(async ():Promise<CheckEventSendDataProps> => {
    try {
      const result = await checkIfSlideCompleted(gameSlideId.id);
      const isCompleted = await result.data.length;
      let maxScore: number = 0;
      if (Number(gameNumber) === 9){
        const topScore = result.data.map((x: { score: any; }) => x.score);
        topScore.sort()
        maxScore = Number(topScore[topScore.length -1]);

      }
      return {isCompleted, maxScore};
    } catch (error) {
      console.log(error);
      return {isCompleted: false, maxScore: 0};
    }
  }, [gameData, shouldMount, postSent]);

  const sendVideoEvent = useCallback(async () => {
    // SENDING EVENT AFTER VIDEO ENDS
    function sendVideoEvent() {
      return axios({
        method: "POST",
        url: endpoints.GAME_EVENT,
        data: {
          action: "Completed",
          coins: 1,
          score: 0,
          slide: gameSlideId.id,
        },
      });
    }

    try {
      const response = await sendVideoEvent();
      if (user) {
        const new_user = {
          ...user,
          userprogress: {
            ...user.userprogress,
            total_coins: response.data.user_total_coins,
          },
        };
        setUser(new_user);
        setLocalStorageUser(new_user);
      }
      const slideNumber = Number(gameNumber) + 1;
      history.push(`/lesson/${lessonNumber}/slide/${slideNumber}`);
    } catch (e) {
      console.log(e);
    }
  }, [gameNumber, gameSlideId]);

  useEffect(() => {
    gameSlideId && getGameData();
  }, [gameSlideId, getGameData]);

  useEffect(() => {
    videoPlaybackRef?.current?.addEventListener("ended", sendVideoEvent);
    return () => {
      videoPlaybackRef?.current?.removeEventListener("ended", sendVideoEvent);
    };
  }, [videoPlaybackRef.current]);

  useEffect(() => {
    if (!postSent && iFrameRef?.current?.contentWindow) {
        sendToIfrme();
    }
  }, [shouldMount, postSent]);

  const sendToIfrme = () => {
    const result = checkEventAndSendDataToIframe();
      result.then(({isCompleted, maxScore } : CheckEventSendDataProps) => {
        setTimeout(() => {
          setPostAsSent(true);
          iFrameRef.current?.contentWindow.postMessage(
            (Number(gameNumber) === 9)?{
              ...lessonDataObject,
              isCurrentSlideCompleted: isCompleted || lessonNumber > 1 ? true : false,
              top_score: maxScore,
            }: {
              ...lessonDataObject,
              isCurrentSlideCompleted: isCompleted || lessonNumber > 1 ? true : false,
            },
            "*",
          );
        }, 1500);
      });
  }

  const onMessageFromIframe = useCallback(
    e => {
      // console.log(e);
      if (e.data === "notloaded") {
        sendToIfrme();
      }
      const statusAwarded = e.data.status === "awardReceived";

      if (gameSlideId && statusAwarded) {
        if (Number(gameNumber) === 10 && lessonNumber < 10) {
          history.push(`/lesson/${+lessonNumber + 1}/slide/1`);
        } else if (Number(gameNumber) === 10 && lessonNumber === 10) {
          history.push(`/lessons`);
        } else {
          const slideNumber = Number(gameNumber) + 1;
          history.push(`/lesson/${lessonNumber}/slide/${slideNumber}`);
        }
      }

      gameSlideId &&
        !statusAwarded &&
        sendGameEvent(
          e.data,
          gameSlideId,
          lessonNumber,
          gameNumber,
          history,
          iFrameRef,
          user,
          setUser,
          setLocalStorageUser,
          lessonDataObject,
        );
    },
    //eslint-disable-next-line
    [gameSlideId, gameNumber, localStorageGame],
  );

  useEffect(() => {
    window.addEventListener("message", onMessageFromIframe);
    return () => window.removeEventListener("message", onMessageFromIframe);
  }, [onMessageFromIframe]);

  const handleToggleMenuState = () => setMenuOpenState(!menuOpenState);
  return (
    <FrameWrapper>
      {shouldMount && +gameNumber !== 2 && +gameNumber !== 8 && (
        <IFrame ref={iFrameRef} frameBorder="0" src={process.env.PUBLIC_URL + "/game.html"} scrolling="no" title="x" />
      )}

      {shouldMount && +gameNumber === 8 && (
              <IFrameHandWriting ref={iFrameRef} frameBorder="0" src={process.env.PUBLIC_URL + "/handwriting.html"} scrolling="yes" title="x" />
      )}

      {isVideoPlayback && lessonVideo ? (
        <VideoFrame controls autoPlay ref={videoPlaybackRef}>
          <source src={`${lessonVideo}`} type="video/mp4" />
          Your browser does not support the video tag.
        </VideoFrame>
      ) : (
        isVideoPlayback && <VideoLoadingScreen>Loading...</VideoLoadingScreen>
      )}
      <GameNav handleToggleMenuState={handleToggleMenuState} />
    </FrameWrapper>
  );
}

export default SingleGame;

const FrameWrapper = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const VideoLoadingScreen = styled.div`
  max-width: 100%;
  width: 1000px;
  height: 530.5px;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IFrame = styled.iframe`
  max-width: 100%;
  width: 1056px;
  height: 600px;
  max-height: 100%;

  @media screen and (max-width: 545px) and (orientation: landscape) {
    height: 100%;
    max-height: 100%;
    width: auto;
  }
`;

const IFrameHandWriting = styled.iframe`
  max-width: 100%;
  width: 1350px;
  height: 650px;
  max-height: 100%;

  @media screen and (max-width: 545px) and (orientation: landscape) {
    height: 100%;
    max-height: 100%;
    width: auto;
  }
`;

const VideoFrame = styled.video`
  max-width: 100%;
  width: 1000px;
  height: 562.5px;
  max-height: 100%;

  @media screen and (max-width: 545px) and (orientation: landscape) {
    height: 100%;
    max-height: 100%;
    width: auto;
  }
`;
