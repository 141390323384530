import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faPlusCircle,
  faSignOutAlt,
  faDatabase,
  faCalendar,
  faUniversity,
  faFile
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { roots } from "../../_urls";
import brush from "../../_assets/brushes/brush.png";
import styled from "@emotion/styled";
import { useRecoilValue, useRecoilState } from "recoil";
import { menuOpenStateAtom } from "../../_store/navigation/store";
import { loggedInUserAtom } from "../../_store/auth/store";
import eiffel from "../../_assets/images/navigation/eiffel.png";
import accordion from "../../_assets/images/navigation/accordion.png";
import coin from "../../_assets/images/navigation/coin.png";
import { useLogout } from "../../_customHooks/auth";
import { isStudentRole, attachNavImage, isAdminRole } from "../../_helpers/theme";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

const Sidenav: React.FC = (): JSX.Element => {
  const closeAfterSelect = () => setMenuState(false);
  const user = useRecoilValue(loggedInUserAtom);
  const logout = useLogout();
  const [isSelected, setisSelected] = useState("Course Content");
  const [open, setMenuState] = useRecoilState<boolean>(menuOpenStateAtom);
  const location = useLocation().pathname;
  const handleLogout = () => {
    closeAfterSelect();
    logout();
  };

  useEffect(() => {
    if (open) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "initial";
    }
  }, [open]);

  const selectHandler = (e: any) => {
    setisSelected("Course Content");
    if (e.target.value == "Online activites"){
      window.location.href = "/lessons"
    } else {
      window.location.href = roots.TEACHING_SLIDES;
    }
  }

  return (
    <List
      open={open}
      lessonView={location === roots.LESSON || location === roots.LESSONS}
      className={classNames("nav-link", {
        "admin-styling": !isStudentRole(user),
      })}
    >
      <NavLink
        onClick={closeAfterSelect}
        className={classNames("nav-link home-link", {
          "admin-styling": !isStudentRole(user),
        })}
        exact
        activeClassName="selected"
        to={roots.DASHBOARD}
      >
        {attachNavImage(user, faHome, eiffel,true)}
        <span className="top-index">Home</span>
      </NavLink>
      {isStudentRole(user) && (
        <CoinInfo className="nav-link">
          <img src={coin} alt="" className="nav-icon nav-coin" />
          <span className="top-index">x {user?.userprogress?.total_coins || 0}</span>
        </CoinInfo>
      )}

      {!isStudentRole(user) && (
        <>
          {/*<NavLink
            onClick={closeAfterSelect}
            className={classNames("nav-link", {
              "admin-styling": !isStudentRole(user),
            })}
            activeClassName="selected"
            to={roots.COURSES}
          >
            <FontAwesomeIcon className="nav-icon courses-icon top-index" icon={faUniversity} />
            <span className="top-index">my courses</span>
          </NavLink>
          <NavLink
            onClick={closeAfterSelect}
            className={classNames("nav-link", {
              "admin-styling": !isStudentRole(user),
            })}
            activeClassName="selected"
            to={roots.CALENDAR}
          >
            <FontAwesomeIcon className="nav-icon class-creation-icon top-index" icon={faCalendar} />
            <span className="top-index">Calendar</span>
          </NavLink>
        </>*/}
        <CourseMenu>
            <FontAwesomeIcon className="nav-icon courses-icon top-index" icon={faUniversity} />
            <DropDown onChange={selectHandler} value={isSelected}>
                <option value={"Course Content"} hidden={true}>Course Content</option>
                <option value={"Online activites"} label={"Online activites"} >
                  Online activites
                   </option>
                <option value={"Teaching slides"} label={"Teaching slides"} > Teaching slides  </option>
            </DropDown>
          </CourseMenu>
         </>
      )}

     {/* {isAdminRole(user) ? (
        <>
          <NavLink
            onClick={closeAfterSelect}
            className={classNames("nav-link", {
              "admin-styling": !isStudentRole(user),
            })}
            activeClassName="selected"
            to={roots.CLASS_CREATION}
          >
            <FontAwesomeIcon className="nav-icon class-creation-icon top-index" icon={faPlusCircle} />
            <span className="top-index">class creation</span>
          </NavLink>
          <NavLink
            onClick={closeAfterSelect}
            className={classNames("nav-link", {
              "admin-styling": !isStudentRole(user),
            })}
            activeClassName="selected"
            to={roots.ANALYTICS}
          >
            <FontAwesomeIcon className="nav-icon class-creation-icon top-index" icon={faDatabase} />
            <span className="top-index">analytics</span>
          </NavLink>
        </>
      ) : null}*/}

      {!isStudentRole(user) ? (
                  <NavLink
                  onClick={() => {
                    window.location.href = roots.GUIDE_DOCS;
                  }}
                  className={classNames("nav-link", {
                    "admin-styling": !isStudentRole(user),
                  })}
                  activeClassName=""
                  to={""}
                >
                  <FontAwesomeIcon className="nav-icon class-creation-icon top-index" icon={faFile} />
                  <span className="top-index">Guides & <br/> Documents</span>
                </NavLink>
      ): null}

      <NavLink
        onClick={closeAfterSelect}
        className={classNames("nav-link user-link", {
          "admin-styling": !isStudentRole(user),
        })}
        to="/"
      >
        {attachNavImage(user, faUser, accordion)}
        <span className="top-index">Hello, {user?.userprofile?.nickname || user?.first_name || user?.username}</span>
      </NavLink>

      <div
        onClick={handleLogout}
        className={classNames("nav-link", {
          "admin-styling": !isStudentRole(user),
        })}
      >
        <FontAwesomeIcon className="nav-icon logout-icon top-index" icon={faSignOutAlt} />
        <span className="top-index">Logout</span>
      </div>
    </List>
  );
};

export default Sidenav;

type Sidenav = {
  open: boolean;
  lessonView: boolean;
};


const DropDown = styled.select`
  font-family: var(--base-font-family);
  font-weight: bold;
  padding: 0 1.25rem;
  background: black;
  color: white;
  border: none;
  font-size: 1rem;
`

const CourseMenu = styled.div`
  display: flex;
  align-items: center;
  color: white;
`;

const List = styled.ul<Sidenav>`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  margin-right: 1.5625rem;
  height: var(--header-height);

  .nav-link {
    color: var(--secondary-500);
    text-decoration: none;
    font-size: var(--student-navigation-font-size);
    margin-left: 1.875rem;
    max-height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: var(--base-font-family);
    font-weight: bold;
    padding: 0 1.25rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${brush});

    &:focus {
      outline: none;
    }

    &.home-link:not(.admin-styling) {
      img {
        width: 2rem;
        margin-bottom: 0;
      }
    }

    &.selected.admin-styling {
      color: var(--primary-600);
    }

    &.user-link.admin-styling {
      opacity: 0.4;
      pointer-events: none;
    }

    &.user-link:not(.admin-styling) {
      padding: 0 2.5rem;
      background-size: 100% 55%;
      padding: 0 1.25rem;
    }

    &.admin-styling {
      color: var(--neutral-1000);
      font-family: var(--base-font-family);
      font-weight: bold;
      margin-left: initial;
      background-image: none;
      transition: all 0.2s ease;
      color: ${({ lessonView }) => (lessonView ? "var(--neutral-1000)" : "var(--neutral-000)")};

      .nav-icon {
        color: ${({ lessonView }) => (lessonView ? "var(--neutral-1000)" : "var(--neutral-000)")};
      }

      @media screen and (max-width: 1080px) and (orientation: landscape) {
        font-size: 0.9rem;
        text-align: center;
      }
    }

    .nav-icon {
      font-size: 1.4375rem;
      margin-right: 0.125rem;
      transition: color 0.2s ease;
      color: var(--neutral-1000);
      width: 2.5rem;

      &.nav-coin {
        width: 3.0rem;
        margin-bottom: 1.1rem;
        margin-right: 0;
      }
    }
    .nav-user {
      margin-bottom: 1.1rem;
    }
  }

  @media (max-width: 998px) {
    left: ${({ open }) => (open ? 0 : "100%")};
    padding-top: var(--header-height);
    background-color: var(--neutral-1000);
    flex-flow: column nowrap;
    position: fixed;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    transition: left 0.3s ease-in-out;
    overflow: hidden;

    &:not(.admin-styling) {
      background-color: var(--body-background-color);
    }

    .nav-link {
      padding: 1.25rem 0.9275rem;
      font-size: 1.2rem;
      margin-left: 0;
      background-size: 100% 70%;
      background-position: 10% 50%;
      width: fit-content;

      &.user-link:not(.admin-styling) {
        background-size: 100% 75%;
        padding: 1.25rem 0.9275rem;
      }

      &:after {
        display: none;
      }

      .nav-icon {
        font-size: 1.4375rem;
        margin-right: 0.9275rem;
      }
    }
  }
`;

const CoinInfo = styled.div`
  color: var(--neutral-000);
`;
