import axios from "axios";
import { endpoints } from "../_endpoints";

export function getAnalytics() {
  return axios({
    method: "GET",
    url: endpoints.ANALYTICS,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default getAnalytics;
