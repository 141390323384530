import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  sectionsAtom,
  sectionUploadSpinner,
} from "../../../_store/class/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faUsers } from "@fortawesome/free-solid-svg-icons";
import useCreateClass from "../../../_customHooks/createNewClass";
import { endpoints } from "../../../_endpoints";
import { useRequest } from "../../../_customHooks/useRequest";
import { SectionType } from "../../../_store/class/models";
import Spinner from "../../../_components/Spinner";
import { animated } from "react-spring";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";

function ClassCreation() {
  useMetaTags(defaultOptions.CLASS_CREATION);
  const [sections, setSections] = useRecoilState<any>(sectionsAtom);
  const [sectionsData]: any = useRequest(endpoints.SECTIONS_ALL, "GET");
  const [selectedSection, setSection] = useState("1");
  const [file, setFile] = useState<null | Blob>(null);
  const createNewClass = useCreateClass(file, selectedSection);
  const loading = useRecoilValue(sectionUploadSpinner);
  const pageTransition = useCustomTransition();

  const changeSection = (event: React.ChangeEvent<HTMLSelectElement>): void =>
    setSection(event.target.value);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files && e.target.files[0];
    setFile(file);
  };

  const handleCreateClass = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    createNewClass();
  };
  useEffect(() => {
    sectionsData && setSections(sectionsData);
  }, [sectionsData, setSections]);

  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <ClassCreationWrapper>
        <Title>Class Creation Tool</Title>
        <Form onSubmit={handleCreateClass}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Label htmlFor="section">
                <FontAwesomeIcon className="label-icon" icon={faUsers} />
                Choose a section where you want to add users.
              </Label>
              <select
                name="section"
                placeholder="search by section name"
                value={selectedSection}
                onChange={changeSection}
              >
                {sections &&
                  sections.map((section: SectionType) => (
                    <option key={section.id} value={section.id}>
                      {section.full_name}
                    </option>
                  ))}
              </select>

              <Label htmlFor="CSV">
                <FontAwesomeIcon className="label-icon" icon={faFile} />
                Choose a csv file with users.
              </Label>
              <Input
                accept=".csv"
                name="CSV"
                type="file"
                onChange={handleFileUpload}
              />

              <SubmitButton type="submit">Submit</SubmitButton>
            </>
          )}
        </Form>
      </ClassCreationWrapper>
    </animated.div>
  );
}

export default ClassCreation;

const ClassCreationWrapper = styled.div`
  width: 100vw;
`;

const Title = styled.h2`
  font-size: var(--page-title-font-size);
  text-align: center;
  color: var(--neutral-1000);
  margin-bottom: 3rem;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 2rem;
    font-size: 1.75rem;
  }
`;

const Form = styled.form`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: var(--container-width);
  margin: 0 auto;
  background-color: var(--neutral-050);
  box-shadow: var(--box-shadow-default);
  margin-bottom: 3rem;

  select {
    margin-bottom: 2rem;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--neutral-150);
    font-size: 1rem;
    outline: none;
    padding: 0.625rem 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }
`;

const Label = styled.label`
  margin-bottom: 2rem;
  font-size: 1.15rem;
  font-weight: bold;

  .label-icon {
    margin-right: 0.75rem;
    font-size: 1.15rem;
  }

  @media screen and (max-width: 545px) and (orientation: portrait) {
    font-size: 1rem;
  }
`;

const Input = styled.input`
  font-size: 1rem;
  outline: none;
  border-bottom: 1px solid var(--neutral-150);
  padding: 0.625rem 0;
  margin-bottom: 2rem;
  text-align: center;
  padding-left: 3.125rem;
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled.button`
  padding: var(--xl-btn-padding);
  font-size: var(--lg-btn-font-size);
  outline: none;
  background-color: var(--primary-500);
  transition: all 0.2s ease;
  border: none;
  box-shadow: var(--box-shadow-default);
  color: var(--neutral-000);
  cursor: pointer;

  &:hover {
    background-color: var(--primary-600);
  }
`;
