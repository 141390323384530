import React from 'react';
import styled from "@emotion/styled";

const ExportButton = styled.button`
    background: linear-gradient(90deg, rgba(224,43,31,1) 0%, rgba(242,144,31,1) 100%);
	border-radius: 30px;
	color: white;
    padding: 10px 25px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
    position: relative;
	font-size: 16px;
    font-weight: 600;
	&:hover {
        background:rgb(242,144,31);background: linear-gradient(90deg, rgba(242,144,31,1) 0%, rgba(224,43,31,1) 100%);
		cursor: pointer;
	}
`;


export default ({ btnText, onExport }: {btnText: String ,onExport: () => void }) => (
    <ExportButton onClick={() => onExport()}>{btnText}</ExportButton>
  );;