import axios from "axios";
import { endpoints } from "../_endpoints";

export function checkIfSlideCompleted(slide: number) {
  return axios({
    method: "GET",
    url: endpoints.EVENT_COMPLETE(slide),
  });
}

export default checkIfSlideCompleted;
