import React from "react";
import styled from "@emotion/styled";
import Sidenav from "./Sidenav";
import { useRecoilState } from "recoil";
import { menuOpenStateAtom } from "../../_store/navigation/store";

const Hamburger = () => {
  const [open, setMenuState] = useRecoilState<boolean>(menuOpenStateAtom);

  const handleMenuVisibility = () => setMenuState(!open);

  return (
    <>
      <HamburgerWrapper open={open} onClick={handleMenuVisibility}>
        <div className="belt" />
        <div className="belt" />
        <div className="belt" />
      </HamburgerWrapper>
      <Sidenav />
    </>
  );
};

export default Hamburger;

const HamburgerWrapper = styled.div<{ open: boolean }>`
  height: 2.2rem;
  z-index: 26;
  width: 2rem;
  position: absolute;
  top: 2rem;
  transform: translateY(-0.5rem);
  right: 1.25rem;
  display: none;

  @media (max-width: 998px) {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .belt {
    width: 2rem;
    height: 0.25rem;
    background-color: var(--neutral-000);
    border-radius: 0.625rem;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-of-type(1) {
      transform: ${({ open }) =>
        open ? "rotate(45deg) translateY(1px) translateX(1px)" : "rotate(0)"};
    }
    &:nth-of-type(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-of-type(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
