import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SingleCustomScheduleRow from "./SingleCustomScheduleRow";
import {
  useSendSchedule,
  scheduleOption,
} from "../../../_customHooks/useSendSchdule";
import { useRecoilValue } from "recoil";
import {
  sectionUploadSpinner,
  sectionClassIdAtom,
} from "../../../_store/class/store";
import { useRequest } from "../../../_customHooks/useRequest";
import { endpoints } from "../../../_endpoints/";
import Spinner from "../../../_components/Spinner";
import { useParams } from "react-router-dom";
import classNames from "classnames";

type RecordType = {
  scheduled_lesson_class?: number;
  lesson?: number;
  datetime: string;
};

function CustomScheduleCreator() {
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const { classId } = useParams<{ classId: string }>();
  const [lessons]: any[] = useRequest(endpoints.LESSONS, "GET");
  const loading = useRecoilValue(sectionUploadSpinner);
  const sectionClassId = useRecoilValue(sectionClassIdAtom);
  const [fullScheduleRecord, setFullScheduleRecord] = useState<RecordType[]>(
    []
  );
  const [buttonState, setButtonState] = useState<boolean>(false);

  useEffect(() => {
    function isFilled(record: RecordType) {
      return record.datetime !== "";
    }

    if (fullScheduleRecord.every(isFilled)) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [fullScheduleRecord]);

  useEffect(() => {
    if (lessons) {
      setFullScheduleRecord(
        lessons.map((lesson: any) => ({
          lesson: lesson.id,
          scheduled_lesson_class: Number(sectionClassId),
          datetime: "",
        }))
      );
    }
  }, [lessons, classId, sectionClassId]);

  const updateRecord = (record: RecordType, index: number) => {
    const newSchedule = [...fullScheduleRecord];
    newSchedule[index] = record;

    setFullScheduleRecord(newSchedule);
  };

  const sendScheduleRequest = useSendSchedule(
    fullScheduleRecord,
    scheduleOption.CUSTOM
  );

  const sendCustomSchedule = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendScheduleRequest();
    setFullScheduleRecord([]);
  };

  return (
    <>
      <Form onSubmit={sendCustomSchedule}>
        {loading ? (
          <Spinner />
        ) : (
          fullScheduleRecord.map((record, i: number) => (
            <SingleCustomScheduleRow
              key={i + 1}
              index={i + 1}
              record={record}
              setRecord={(record) => updateRecord(record, i)}
            />
          ))
        )}
        <button
          className={classNames("default-button", { disabled: !buttonState })}
          type="submit"
        >
          Create Schedule
        </button>
      </Form>
    </>
  );
}

export default CustomScheduleCreator;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .default-button {
    padding: var(--xl-btn-padding);
    font-size: var(--lg-btn-font-size);
    outline: none;
    background-color: var(--primary-500);
    transition: all 0.2s ease;
    border: none;
    box-shadow: var(--box-shadow-default);
    color: var(--neutral-000);
    cursor: pointer;
    width: fit-content;
    margin-top: 2rem;

    &:hover {
      background-color: var(--primary-600);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
      cursor: disabled;

      :hover {
        color: var(--neutral-1000);
      }
    }
  }
  @media screen and (max-width: 745px) and (orientation: portrait) {
    flex-direction: column;
    width: 100%;
  }
`;
