import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  hourSet,
  minuteSet,
  getISODateFromDatePicker,
  defaultValue,
  i18n,
} from "../../../_helpers/dates";
import { useRecoilValue } from "recoil";
import {
  sectionUploadSpinner,
  sectionClassIdAtom,
} from "../../../_store/class/store";
import {
  useSendSchedule,
  scheduleOption,
} from "../../../_customHooks/useSendSchdule";
import { DatePicker } from "react-vaadin-components";
import { useParams } from "react-router-dom";

type recordType = {
  scheduled_lesson_class?: number;
  datetime?: string;
};

function WeeklyScheduleCreator() {
  const [weeklyRecord, setWeeklyRecord] = useState<recordType>({});
  const [selectedHour, setHour] = useState(hourSet[0]);
  const [selectedMinutes, setMinutes] = useState(minuteSet[0]);

  const [selectedDate, setDate] = useState("");
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const { classId } = useParams<{ classId: string }>();

  const weekDayName = new Date(selectedDate).toLocaleString("en", {
    weekday: "long",
  });

  const sendScheduleRequest = useSendSchedule(
    weeklyRecord,
    scheduleOption.WEEKLY
  );
  const loading = useRecoilValue(sectionUploadSpinner);
  const sectionClassId = useRecoilValue(sectionClassIdAtom);

  const sendCustomSchedule = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendScheduleRequest();
    setWeeklyRecord({});
  };

  useEffect(() => {
    if (
      selectedHour !== defaultValue &&
      selectedMinutes !== defaultValue &&
      selectedDate
    ) {
      const dt = getISODateFromDatePicker(
        selectedDate,
        selectedHour,
        selectedMinutes
      );

      const record = {
        scheduled_lesson_class: Number(sectionClassId),
        datetime: dt,
      };

      setWeeklyRecord(record);
    }
  }, [selectedHour, selectedMinutes, selectedDate, classId, sectionClassId]);

  return (
    <Form onSubmit={sendCustomSchedule}>
      {loading ? (
        "Uploading data"
      ) : (
        <>
          {selectedHour !== defaultValue &&
            selectedMinutes !== defaultValue &&
            selectedDate && (
              <p>
                <b>Lessons happen each week on:</b>

                <span className="weekday-name">{weekDayName}</span>
              </p>
            )}

          <p>
            <b>Lessons start at:</b>
          </p>
          <div className="lesson-hour-date-form">
            <select
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setHour(e.target.value)
              }
              value={selectedHour}
            >
              {hourSet.map((hour: number) => (
                <option key={hour}>{hour}</option>
              ))}
            </select>
            <select
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setMinutes(e.target.value)
              }
              value={selectedMinutes}
            >
              {minuteSet.map((minutes: number) => (
                <option key={minutes}>{minutes}</option>
              ))}
            </select>
          </div>

          <p>
            <b>Course starts on:</b>
          </p>
          <DatePicker
            i18n={i18n}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setDate(e.target.value)
            }
            value={selectedDate}
          ></DatePicker>
        </>
      )}
      <button className="default-button" type="submit">
        Create Schedule
      </button>
    </Form>
  );
}

export default WeeklyScheduleCreator;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--neutral-050);
  padding: 1rem 3rem 2rem 1rem;
  margin: 2rem 0;

  .weekday-name {
    display: inline-flex;
    padding: 0.3125rem 0.625rem;
    background-color: var(--body-background-color);
    font-weight: bold;
    margin-left: 1rem;
  }

  @media screen and (max-width: 745px) and (orientation: portrait) {
    width: 100%;

    p {
      font-size: 1.1rem;
      margin-bottom: 1rem;
      width: 100%;
      text-align: left;
      font-weight: bold;
    }
  }

  .input-wrapper {
    display: block;
    position: relative;
    padding-left: 2.1875rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      .radio_input ~ .checkmark {
        background-color: var(--neutral-150);
      }
    }

    .radio_input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: var(--primary-600);
      }

      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.25rem;
      width: 1.25rem;
      background-color: var(--neutral-100);

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 0.4375rem;
        top: 0.1875rem;
        width: 0.25rem;
        height: 0.5rem;
        border: solid var(--neutral-000);
        border-width: 0 0.1875rem 0.1875rem 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .lesson-hour-date-form {
    select {
      padding: 0.3125rem 0.625rem;
      border: 1px solid var(--neutral-100);
      border-radius: 0;
      outline: none;
    }

    option {
      font-size: 1rem;
    }

    @media screen and (max-width: 745px) and (orientation: portrait) {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      select {
        font-size: 1.1rem;
        margin: 0 0.1rem;
      }
    }
  }
`;
