import { atom } from "recoil";

export type notificationProps = {
  type: string;
  text: string;
  visible: boolean;
};

export enum notificationOptions {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "information",
}

export const initialState = {
  type: "",
  text: "",
  visible: false,
};

export const notificationAtom = atom({
  key: "notificationAtom",
  default: {
    type: "",
    text: "",
    visible: false,
  } as notificationProps,
});
