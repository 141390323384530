import styled from "@emotion/styled";

export const Title = styled.h2`
  font-size: var(--student-page-title-font-size);
  font-family: var(--base-font-family);
  text-align: center;
  color: var(--secondary-500);
  margin-bottom: 1.75rem;
  margin-top: 2rem;
  font-weight: normal;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    padding: 0 0.9375rem;
    font-size: 2.5rem;
    max-width: 100%;
  }
`;
