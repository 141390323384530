import React from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";


export function TeachingSlides() {
  useMetaTags(defaultOptions.TEACHING_SLIDES);
  // const history = useHistory();

  return (
    <ClassCreationWrapper>
      <Title>Teaching Slides</Title>
      <MyCourses>
        <Course >
            <Button href={"/docs/Lesson_1_-_ArabicConnect.pdf"}>
            Lesson 1: Hello!
            </Button>
            <Button href={"/docs/Lesson_2_-_ArabicConnect.pdf"}>
            Lesson 2: What's your name?
            </Button>
            <Button href={"/docs/Lesson_3_-_ArabicConnect.pdf"}>
            Lesson 3: How old are you?
            </Button>
            <Button href={"/docs/Lesson_4_-_ArabicConnect.pdf"}>
            Lesson 4: My Family
            </Button>
        </Course>
        <Course >
            <Button href={"/docs/Lesson_5_-_ArabicConnect.pdf"}>
            Lesson 5: Where do you live?
            </Button>
            <Button href={"/docs/Lesson_6_-_ArabicConnect.pdf"}>
            Lesson 6: Adjectives
            </Button>
            <Button href={"/docs/Lesson_7_-_ArabicConnect.pdf"}>
            Lesson 7: Colours
            </Button>
            <Button href={"/docs/Lesson_8_-_ArabicConnect.pdf"}>
            Lesson 8: Food & Drink
            </Button>
        </Course>
        <Course >
            <Button href={"/docs/Lesson_9_-_ArabicConnect.pdf"}>
            Lesson 9: Likes & Dislikes
            </Button>
            <Button href={"/docs/Lesson_10_-_ArabicConnect.pdf"}>
            Lesson 10: Review
            </Button>
        </Course>
        
      </MyCourses>
    </ClassCreationWrapper>
  );
}

export function GuideDocs() {
    useMetaTags(defaultOptions.GUIDE_DOCS);
    const history = useHistory();
  
    return (
      <ClassCreationWrapper>
        <Title>Guides & Documents</Title>
        <MyCourses>
          <Course >
              <Button href={"/docs/CL_Results_Guide_(Arabic).pdf"}>
              School administrator guide for results
              </Button>
              <Button href={"/docs/Level-1-Arabic-Curriculum-and-Wordlist.pdf"}>
              Curriculums
              </Button>
              <Button href={"/docs/CL_Results_Guide_(Arabic).pdf"}>
              How to interpret the results
              </Button>
              <Button href={"/docs/Arabic_Connect_Lesson_Unlock.pdf"}>
              How to unlock the lessons
              </Button>
          </Course>
        </MyCourses>
      </ClassCreationWrapper>
    );
  }



const ClassCreationWrapper = styled.div`
  width: 100vw;
  padding: 0 1rem;
`;

const Title = styled.h2`
  font-size: var(--page-title-font-size);
  text-align: center;
  color: var(--neutral-1000);
  margin-bottom: 3rem;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 2rem;
    font-size: 1.75rem;
  }
`;

const MyCourses = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--container-width);
  margin: 0 auto;
  margin-bottom: 3rem;
`;

const CourseLabel = styled.div`
  font-size: 1.05rem;

  height: 100%;
  font-weight: bold;

  .label-icon {
    margin-right: 0.75rem;
    font-size: 1.15rem;
  }
`;

const Course = styled.div`
  background-color: var(--neutral-000);
  font-size: 1rem;
  outline: none;
  padding: 0.625rem 1.875rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--neutral-050);
  box-shadow: var(--box-shadow-default);

  @media screen and (max-width: 545px) {
    max-width: 80vw;
    flex-direction: column;
    padding: 2rem;
  }
`;

const Button = styled.a`
  background: linear-gradient(90deg, #e02b20 0%, #ec6d1f 100%);
  border-color: transparent;
  color: #fff !important;
  border-radius: 5px;
  font-weight: bold;
  height: 70px;
  display: block;
  text-align: center;
  padding: 10px;
  padding-top: 1.31rem;
  word-wrap: break-word;
  white-space: normal;
  width: 15rem;
  font-size: 1rem;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 5px rgba(27,28,27,0.25);
  }

  @media screen and (max-width: 545px) {
    margin-top: 2rem;
  }
`;

