export enum roots {
  LOGIN = "/login",
  DASHBOARD = "/",
  LOGOUT = "/login",
  LESSONS = "/lessons",
  BADGES = "/badges",
  LESSON = "/lesson",
  CLASS = "/class",
  COURSES = "/courses",
  CLASS_CREATION = "/class-creation",
  TEACHING_SLIDES = "/teaching-slides",
  GUIDE_DOCS = "/guid-docs",
  STUDENT = "/student",
  CALENDAR = "/calendar",
  ANALYTICS = "/analytics",
  FORCE_PROGRESS = "force-student-progress",
}
