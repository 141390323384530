import React from "react";
import { useRecoilValue } from "recoil";
import { csrfTokenAtom } from "../../../_store/auth/store";

function CSRFToken() {
  const tokenValue = useRecoilValue(csrfTokenAtom);

  return (
    <input type="hidden" name="csrfmiddlewaretoken" value={tokenValue || ""} />
  );
}

export default CSRFToken;
