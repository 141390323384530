import React from "react";
import { Chart } from "react-google-charts";
import styled from "@emotion/styled";
import Spinner from "../../../_components/Spinner";

function StudentChart({
  studentAvg,
  classAvg,
  title,
  subtitle,
  maxValue,
}: {
  studentAvg: number;
  classAvg: number;
  title: string;
  subtitle: string;
  maxValue: number;
}) {
  return (
    <>
      <LabelsWrapper>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </LabelsWrapper>
      <Chart
        width={"100%"}
        height={"35rem"}
        chartType="Bar"
        loader={<Spinner />}
        data={[
          ["", "Average"],
          ["Student", studentAvg],
          ["Class", classAvg],
        ]}
        options={{
          legend: { position: "none" },
          axes: {
            y: {
              all: {
                range: {
                  min: 0,
                  max: maxValue,
                },
              },
            },
          },
        }}
      />
    </>
  );
}

export default StudentChart;

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;

const Title = styled.span`
  margin-bottom: 0.7rem;
  color: var(--neutral-800);
  font-size: 1rem;
`;
const Subtitle = styled.span`
  color: var(--neutral-250);
  font-size: 0.9rem;
`;
