import React from "react";
import styled from "@emotion/styled";
import StudentChart from "./Chart";
import { endpoints } from "../../../_endpoints/";
import { useParams } from "react-router-dom";
import { useRequest } from "../../../_customHooks/useRequest";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";

function Student() {
  const { userId } = useParams<{ userId: string }>();
  const [userProgress]: any = useRequest(
    endpoints.USER_PROGRESS(userId),
    "GET"
  );
  useMetaTags(defaultOptions.STUDENT_DETAIL);

  const history = useHistory();

  const [userStatistics]: any = useRequest(
    endpoints.USER_STATISTICS(userId),
    "GET"
  );

  const totalEarnedbadges = userProgress?.userprogress?.badges.filter(
    (badge: any) => badge.earned
  ).length;
  const allBadges = userProgress?.userprogress?.badges;
  const totalAllBadges = userProgress?.userprogress?.badges.length;

  return (
    <StudentWrapper>
      {userStatistics && userProgress ? (
        <>
          <Title>
            <BackButton onClick={() => history.go(-1)}>
              <FontAwesomeIcon
                className="goback-icon"
                icon={faLongArrowAltLeft}
              />{" "}
              go back
            </BackButton>
            {userProgress.first_name} {userProgress.last_name}
          </Title>
          <Subtitle>Student's statistics</Subtitle>
          <ChartsWrapper>
            <ChartWrapper>
              <StudentChart
                studentAvg={userStatistics.course_completion}
                classAvg={userStatistics.course_completion_class_avg}
                title="Course Completion"
                subtitle="The percentage of the course that the student has completed."
                maxValue={100}
              />
            </ChartWrapper>
            <ChartWrapper>
              <StudentChart
                studentAvg={userProgress.userprogress.total_coins}
                classAvg={userStatistics.coins_class_avg}
                title="trophys"
                subtitle="The number of coins that the student has obtained."
                maxValue={200}
              />
            </ChartWrapper>
            {/* <ChartWrapper>
              <StudentChart
                studentAvg={userStatistics.login_days}
                classAvg={userStatistics.login_days_class_avg}
                title="Login Days"
                subtitle="The number of individual days on which the student has logged in."
              />
            </ChartWrapper> */}
            <ChartWrapper>
              <StudentChart
                studentAvg={userStatistics.completed_games}
                classAvg={userStatistics.completed_games_class_avg}
                title="Completed Games"
                subtitle="The number of games which the student has completed with at least one trophy." 
                maxValue={100} 
              />
            </ChartWrapper>
            {/* <ChartWrapper>
              <StudentChart
                studentAvg={userStatistics.slide_views}
                classAvg={userStatistics.slide_views_class_avg}
                title="Slide Views"
                subtitle="The total number of times the student has viewed course slides."
              />
            </ChartWrapper> */}
          </ChartsWrapper>

          {allBadges && allBadges.length ? (
            <BadgesWrapper>
              <Subtitle>{`${userProgress.first_name} ${userProgress.last_name} has ${totalEarnedbadges} ${totalEarnedbadges <= 1?"badge": "badges"} out of ${totalAllBadges} available badges.`}</Subtitle>
              <BadgesList>
                {allBadges.map((badge: any) => {
                  return (
                    badge.earned?
                    <Badge>
                      <BadgePicture src={badge.image} />
                      <BadgeDescription>
                        <BadgeName>{badge.name}</BadgeName>
                        <BadgeFor>{badge.description}</BadgeFor>
                      </BadgeDescription>
                    </Badge>:
                    <Badge>
                      <BadgePictureGrey src={badge.image} />
                      <BadgeDescription>
                        <BadgeName>{badge.name}</BadgeName>
                        <BadgeFor>{badge.description}</BadgeFor>
                      </BadgeDescription>
                    </Badge>
                  );
                })}
              </BadgesList>
            </BadgesWrapper>
          ) : (
            <></>
          )}
        </>
      ) : (
        <LoadingInfo>Loading charts...</LoadingInfo>
      )}
    </StudentWrapper>
  );
}

export default Student;

const BadgesWrapper = styled.div`
  max-width: 80vw;
  text-align: center;
  margin: 2rem auto 1rem auto;
`;

const StudentWrapper = styled.div`
  width: 100vw;
  padding: 2rem;
`;

const BackButton = styled.span`
  font-size: 1.1rem;
  color: var(--neutral-300);
  position: absolute;
  left: 0;
  cursor: pointer;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 0;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    position: initial;
  }

  .goback-icon {
    margin-right: 0.5rem;
  }

  :hover {
    color: var(--primary-600);
  }
`;

const Title = styled.h2`
  font-size: var(--page-title-font-size);
  text-align: center;
  color: var(--neutral-1000);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 60rem;
  margin: 3rem auto;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column-reverse;
  }
`;

const Subtitle = styled.h3`
  text-align: center;
  color: var(--neutral-1000);
  margin-bottom: 3rem;
`;

const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChartWrapper = styled.div`
  margin: 2rem;
  width: 60rem;

  @media screen and (max-width: 998px) {
    max-width: 90vw;
    margin-bottom: 5rem;
  }
`;

const Badge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.3rem;
  max-width: var(--badge-width);
`;

const BadgePicture = styled.img`
  width: 5rem;
  padding: 0;
  height: auto;
`;

const BadgePictureGrey = styled.img`
  width: 5rem;
  padding: 0;
  height: auto;
  filter: grayscale(100%) opacity(70%);
`;

const BadgeDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const BadgeName = styled.p`
  margin-bottom: 0.3rem;
  font-weight: bold;
`;

const BadgeFor = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const BadgesList = styled.div`
  max-width: var(--container-width);
  margin: 2rem auto 3rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--badge-width));
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const LoadingInfo = styled.div`
  margin: 0 auto;
  text-align: center;
  padding-top: 12rem;
  font-size: 2rem;
  color: var(--neutral-200);
`;
