import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classNames from "classnames";
import { animated } from "react-spring";
import { faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";
import { useRequest } from "../../../_customHooks/useRequest";
import { endpoints } from "../../../_endpoints/";
import DataTable, { Alignment } from 'react-data-table-component';
import ExportButton from "./ExportButton";
import { downloadReport } from "./_helper";
import Spinner from "../../../_components/Spinner";


function SchoolResultTable({ classid }: any) {
  useMetaTags(defaultOptions.SCHOOL_RESULT);
  const [schoolData, loading]: any = useRequest(endpoints.SCHOOL_RESULT(classid), "GET");
  const pageTransition = useCustomTransition();
  const [filterText, setFilterText] = React.useState('');
  const ouputHeader: string[] = ["Class", "Course Progress", "Trophys", "Login Days", "Badges", "Trophys & Badges Combo"];
  const schoolName: string = decodeURI(window.location.search.substring(1).split('=')[1])
  const reportTitle = `${schoolName} - School Result`


  // eslint-disable-next-line react/prop-types
  const FilterComponent = ({ filterText, onFilter, onClear }: { filterText: string, onFilter: (e: any) => void, onClear: () => void }) => (
    <>
      {/* <TextField
                id="search"
                type="text"
                placeholder="Filter By Class"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
            <ClearButton type="button" onClick={onClear}>
                X
            </ClearButton> */}
      <FilterSection>
        <InputWrapper>
          <FontAwesomeIcon
            // onClick={() => handleSelectFilterMethod(FilterMethod.TYPE)}
            className={classNames("filter_icon", {
              active: true,
            })}
            icon={faSearch}
          />
          <Input
            autoFocus
            placeholder="search by class name"
            value={filterText}
            type="text"
            onChange={onFilter}
            className={classNames({
              active: true,
            })}
          />
        </InputWrapper>
      </FilterSection>
    </>
  );
  const columnsSchoolResult = [
    {
      name: 'Class',
      selector: (row: any) => row.class_name,
      sortable: true,
    },
    {
      name: 'Course completion (1-10)',
      selector: (row: any) => row.course_progress,
      sortable: true,
    },
    {
      name: 'Trophys',
      selector: (row: any) => row.trophys,
      sortable: true,
    },
    {
      name: 'Login Days',
      selector: (row: any) => row.login_days,
      sortable: true,
    },
    {
      name: 'Badges',
      selector: (row: any) => row.badges,
      sortable: true,
    },
    {
      name: 'Trophys & Badges Combo',
      selector: (row: any) => row.trophys_and_badges,
      sortable: true,
    },
  ];

  const customStyles = {
    table: {
      style: {
        border: '1px solid lightgrey',
      },
    },
    head: {
      style: {
        fontSize: '18px',
        fontWeight: '700',
      },
    },
    rows: {
      style: {
        fontSize: '15px',
        fontWeight: '700',
      },
    },
    headCells: {
      style: {
        paddingLeft: '0.3rem',
        paddingRight: '1px',
      },
    },
    subHeader: {
      style: {
        padding: '1px',
      },
    },
  };


  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText]);

  const actionsMemoSchoolResult = React.useMemo(() => <ExportButton btnText={"Download School Data"} onExport={() => downloadReport(schoolData, reportTitle, ouputHeader, "school_result_report")} />, [schoolData]);
  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      {/* <Title>Class Result</Title> */}
      <ContentWrapper>
        <DataTable
          defaultSortFieldId={1}
          title="School Result"
          columns={columnsSchoolResult}
          data={schoolData?.filter(
            (item: any) => item.class_name && item.class_name.toLowerCase().includes(filterText.toLowerCase())
          )}
          customStyles={customStyles}
          actions={actionsMemoSchoolResult}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          subHeaderAlign={Alignment.LEFT}
          responsive={true}
          progressPending={loading}
          progressComponent={<Spinner />}
        />
      </ContentWrapper>
    </animated.div>
  );
}

export default SchoolResultTable;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    flex-direction: column;
    display: flex;
    width: 100%;
    padding: 2rem 0;
  }
`;

const FilterSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
  }
`;

const InputWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 15px;

  .filter_icon {
    height: 100%;
    position: relative;
    top: 10px;
    margin-right: 10px;
    color: #ccc;
    cursor: pointer;

    &.active {
      color: var(--primary-500);
    }
  }
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  font-size: 1rem;
  outline: none;
  margin-bottom: 3rem;
  text-align: center;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease;
  height: 40px;

  &.active {
    width: 18.75rem;
    padding: 0.625rem 1.875rem;
    border-bottom: 1px solid var(--neutral-150);
  }

  @media screen and (max-width: 545px) and (orientation: portrait) {
    &.active {
      width: 18.75rem;
      padding: 0.625rem 1.875rem;
      border-bottom: 1px solid var(--neutral-150);
    }
  }
`;
