import React, { useEffect, useState} from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";


interface props {
  handleToggleMenuState: () => void;
}

const GameNav: React.FC<props> = (props: props) => {
  const [isTabletWidth, setIsTabletWidth] = useState(false);

  useEffect(() => {
    window.innerWidth < 998 ? setIsTabletWidth(true) : setIsTabletWidth(false);
  }, [window.innerWidth]);


  return(
    <NavigationBar>
      {isTabletWidth && (
        <Button onClick={props.handleToggleMenuState} className="menu_button">
            {" "}
            <FontAwesomeIcon className="notification-icon" icon={faBars} />
        </Button>
      )}
    </NavigationBar>
  )
}

export default GameNav;

const NavigationBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 998px) and (orientation: landscape) {
    margin-top: 0.5rem;
  }
`;

const Button = styled.button`
  border: none;
  background-color: var(--secondary-500);
  padding: var(--xl-btn-padding);
  font-weight: bold;
  margin: 0 0.625rem;
  border-radius: 0.9375rem;
  color: var(--neutral-000);
  font-size: 1.2rem;
  outline: none;
  cursor: pointer;

  &.menu_button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 545px) and (orientation: portrait) {
    font-size: 1rem;
    margin: 0;
    padding: var(--sm-btn-padding);

    &.menu_button {
      margin: 0 1rem;
    }
  }

  @media screen and (max-width: 998px) and (orientation: landscape) {
    font-size: 1rem;
    margin: 0;
    padding: var(--sm-btn-padding);

    &.menu_button {
      margin: 0 1rem;
    }
  }
`;
