import axios from "axios";
import { SetterOrUpdater } from "recoil";
import { endpoints } from "../_endpoints";
import { base } from "../_endpoints/index";
import { LocalStorageKeys } from "../_helpers/localStorage";
import { User } from "../_store/auth/models";

type Badge = {
  description: string;
  eardned: boolean;
  image: string;
  name: string;
};

type GameEvent = {
  action: string;
  award?: string;
  coins?: number;
  score?: number;
  slide?: number;
  status?: string;
  customAward?: string[];
};

const GameEventStatus = "gameEvent";

function sendGameEvent(
  data: GameEvent,
  slideId: any,
  lessonNumber: number,
  gameNumber: string,
  history: { push: (url: string) => void },
  iFrameRef: { current: any },
  user: User | null,
  setUser: SetterOrUpdater<User | null>,
  setLocalStorageUser: SetterOrUpdater<User | null>,
  currentLessonData?: any,
  video?: boolean,
) {
  const { status, customAward, ...eventData } = data;
  eventData.slide = slideId.id;
  const coinsEarnedInRound = data.coins;
  const savedLessonData = window.localStorage.getItem(LocalStorageKeys.GAME);
  const parsedLessonData = savedLessonData && JSON.parse(savedLessonData);

  if (data.status === GameEventStatus && user) {
    try {
      let promises: Promise<void>[] = [];
      let badges: Badge[] = [];

      promises.push(
        sendEventAction(eventData).then(response => {
          response.data.badges.forEach((badge: Badge) => {
            if (!badgeInArray(badge, badges)) badges.push(badge);
          });
        }),
      );

      if (coinsEarnedInRound && parsedLessonData?.coins_earned < coinsEarnedInRound) {
        window.localStorage.setItem(
          LocalStorageKeys.GAME,
          JSON.stringify({ ...parsedLessonData, coins_earned: coinsEarnedInRound }),
        );

        const initialCoinsValue = user.userprogress.total_coins as number;
        for (let i = parsedLessonData.coins_earned; i < coinsEarnedInRound; i++) {
          const coinUpdateTime = 1200 + i * 500;

          setTimeout(() => {
            const new_user = {
              ...user,
              userprogress: {
                ...user.userprogress,
                total_coins: initialCoinsValue + 1 + i,
              },
            };
            setUser(new_user);
            setLocalStorageUser(new_user);
          }, coinUpdateTime);
        }
      }

      if (customAward?.length) {
        customAward.forEach(async award => {
          promises.push(
            sendCustomEvent(award).then(response => {
              response.data.badges.forEach((badge: Badge) => {
                if (!badgeInArray(badge, badges)) badges.push(badge);
              });
            }),
          );
        });
      }

      Promise.all(promises).then(() => {
        badges.length && sendEventAwardDataBack(badges, currentLessonData);
      });
    } catch (error) {
      console.log(error);
    }
  }

  /* ---------------- helpers ---------------- */
  function badgeInArray(badge: Badge, array: Badge[]) {
    return array.some(element => {
      return element.name === badge.name;
    });
  }

  function redirectToNextGameOrLesson() {
    if (Number(gameNumber) === 10 && lessonNumber < 10) {
      history.push(`/lesson/${+lessonNumber + 1}/slide/1`);
    } else if (Number(gameNumber) === 10 && lessonNumber === 10) {
      history.push(`/lessons`);
    } else {
      const slideNumber = Number(gameNumber) + 1;
      history.push(`/lesson/${lessonNumber}/slide/${slideNumber}`);
    }
  }

  function sendEventAwardDataBack(data: any, currentLessonData: any) {
    iFrameRef.current.contentWindow.postMessage({
      status: "awardDetection",
      award: data,
      lessonData: currentLessonData,
      baseLink: base,
      lessonNumber: lessonNumber,
      gameNumber: Number(gameNumber),
    });
  }

  async function sendCustomEvent(name: string) {
    return axios({
      method: "POST",
      url: endpoints.GAME_EVENT,
      data: {
        action: "Awarded",
        award: name,
      },
    });
  }

  async function sendEventAction(data: any) {
    return axios({
      method: "POST",
      url: endpoints.GAME_EVENT,
      data,
    });
  }
}

export default sendGameEvent;
