import React from "react";
import styled from "@emotion/styled";
import NotFoundImage from "../../../_assets/images/404.jpg";

function NotFound() {
  return (
    <NotFoundWrapper>
      <Img src={NotFoundImage} alt="404 illustration" />
    </NotFoundWrapper>
  );
}

export default NotFound;

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - (var(--header-height)));
  font-size: 1.25rem;
  color: var(--neutral-750);
`;

const Img = styled.img`
  height: auto;
  width: 25rem;
  max-width: 100%;
`;
