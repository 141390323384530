import React, { useEffect } from "react";
import styled from "@emotion/styled";
import GameSideBar from "./GameSideBar";
import {
  useRouteMatch,
  Switch,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import PrivateRoute from "../../../_components/PrivateRoute";
import SingleGame from "./SingleGame";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { animated } from "react-spring";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentLessonIndex } from "../../../_store/lessons/store";
import { endpoints } from "../../../_endpoints";
import { useRequest } from "../../../_customHooks/useRequest";
import { LessonProps } from "../../../_store/lessons/models";
import { lessonsAtom } from "../../../_store/lessons/store";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";
import { roles } from "../../../_helpers/roles";
import { loggedInUserAtom } from "../../../_store/auth/store";
import { getLessons } from "../../../_customHooks/getLessons";

function Lesson() {
  let { path } = useRouteMatch();
  const history = useHistory();
  const user = useRecoilValue(loggedInUserAtom);
  const isStudent = user?.userprofile.role === roles.STUDENT;
  const { lessonNumber } = useParams<{
    lessonNumber: string;
  }>();
  const { pathname } = useLocation<{ pathname: string }>();
  const pageTransition = useCustomTransition();
  const [, setLessonIndex] = useRecoilState(currentLessonIndex);
  const [lessonData]: any = useRequest(endpoints.LESSONS, "GET");
  const [updatedLessonData, setLessonData] = useRecoilState<LessonProps[]>(
    lessonsAtom
  );
  useMetaTags(`${defaultOptions.LESSONS} ${lessonNumber}`);

  const updateLessonData = async () => {
    try {
      const result = await getLessons();
      result && result.data && setLessonData(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    lessonData && setLessonData(lessonData);
  }, [lessonData, setLessonData]);

  useEffect(() => {
    if (lessonData) {
      updateLessonData();
    }
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    setLessonIndex(+lessonNumber);
  }, [lessonNumber, setLessonIndex]);

  useEffect(() => {
    if (lessonData && !lessonData[+lessonNumber - 1].unlocked && isStudent) {
      history.go(-1);
    }
  }, [lessonData, history, isStudent, lessonNumber]);

  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <LessonWrapper>
        <GameSideBar lessonData={updatedLessonData} />
        <GameWrapper>
          <Switch>
            <PrivateRoute
              exact
              path={`${path}/slide/:gameNumber`}
              component={SingleGame}
            />
          </Switch>
        </GameWrapper>
      </LessonWrapper>
    </animated.div>
  );
}

export default Lesson;

const LessonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - (var(--header-height)));
  max-height: calc(100vh - (var(--header-height)));
  /* overflow: hidden; */
`;

const GameWrapper = styled.div`
  width: 100%;
  background-color: var(--neutral-000);
  height: calc(100vh - (var(--header-height)));

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
