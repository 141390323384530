import axios from "axios";
import { endpoints } from "../_endpoints";

export function getSingleSlide(id?: any) {
  return axios({
    method: "GET",
    url: `${endpoints.SLIDE}${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default getSingleSlide;
