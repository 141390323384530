import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilValue, useRecoilState } from "recoil";
import { loggedInUserAtom } from "../../../_store/auth/store";
import {
  filterClassesState,
  classesFilterState,
  filterBySchoolClasses,
  classesAtom,
} from "../../../_store/class/store";
import { notificationAtom, notificationOptions } from "../../../_store/notification/store";
import { attachProperTitle } from "./_helpers";
import SingleClassInfo from "./SingleClassInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import { ClassTypes } from "../../../_store/class/models";
import { animated } from "react-spring";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";
import classNames from "classnames";
import { ApiClient } from "../../../_axios";

enum FilterMethod {
  SELECT = "select",
  TYPE = "type",
}

function AdminDashboard() {
  useMetaTags(defaultOptions.DASHBOARD);
  const user = useRecoilValue(loggedInUserAtom);
  const [, setClassesStore] = useRecoilState(classesAtom);
  const classListWithFilter = useRecoilValue(filterClassesState);
  const [filter, setFilter] = useRecoilState(classesFilterState);
  const groupedClassList = useRecoilValue(filterBySchoolClasses);
  const [, setNotification] = useRecoilState(notificationAtom);
  const pageTransition = useCustomTransition();
  const updateFilter = (event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value);
  const [activePane, setActivePane] = useState<number>(1);
  const [schools, setSchools] = useState<string[] | null>(null);
  const [sections, setSections] = useState<string[] | null>(null);
  const [schoolClasses, setSchoolClasses] = useState<string[] | null>(null);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedSchoolClass, setSelectedSchoolClass] = useState("");
  const [selectedFilterMethod, setSelectedFilterMethod] = useState<string>(FilterMethod.TYPE);

  const privateTuitionGroup = groupedClassList[0] as ClassTypes[];
  const nonPrivateTuitionGroup = groupedClassList[1] as ClassTypes[];

  const handleSchoolSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setSelectedSchool(e.target.value);
    console.log(selectedSchool, e.target.value)
    if (sections){
      const filterClass = sections?.filter((el: string) => el.toLowerCase().includes(e.target.value.toLowerCase()))
      .map((fel: string) => fel.replace(e.target.value, "").split("Section")[0]);
      setSchoolClasses([... new Set(filterClass)]);
    }
  };

  const handleSchoolClassSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setSelectedSchoolClass(e.target.value);
    console.log(selectedSchoolClass, e.target.value);
  };

  const searchSchoolClass = () => {
    console.log(selectedSchool, selectedSchoolClass, filter);
    if (filter.length){
      setFilter("");
    }
    setFilter(`${selectedSchool.trim()} ${selectedSchoolClass.trim()}`.replace("no_selection", "").trim());
    setSelectedSchoolClass("");
  };

  const showSchools = () => {
    if (activePane === 1) {
      return nonPrivateTuitionGroup.map((classData: ClassTypes, index: number) => (
        <SingleClassInfo key={index} classData={classData} />
      ));
    } else if (activePane === 0) {
      return privateTuitionGroup.map((classData: ClassTypes, index: number) => (
        <SingleClassInfo key={index} classData={classData} />
      ));
    }
  };

  const getSchoolNames = (classes: ClassTypes[]) => {
    return [...classes].map((classObj: ClassTypes) => classObj.school_name);
  };

  const getSectionNames = (classes: ClassTypes[]) => {
    return [...classes].map((classObj: ClassTypes) => classObj.full_name);
  };


  // const handleSelectFilterMethod = (method: string) => {
  //   setSelectedFilterMethod(method);
  // };

  useEffect(() => {
    // setFilter(selectedSchool);
    if (selectedSchool === "no_selection") {
      setFilter("");
    }

    if (selectedSchool.includes("Private") && activePane !== 0) {
      setActivePane(0);
    } else if (!selectedSchool.includes("Private") && activePane === 0) {
      setActivePane(1);
    }
    // eslint-disable-next-line
  }, [selectedSchool, setActivePane]);

  // useEffect(() => {
  //   setFilter(selectedSchoolClass);
  //   if (selectedSchoolClass === "no_selection") {
  //     setFilter("");
  //   }

  //   if (selectedSchool.includes("Private") && activePane !== 0) {
  //     setActivePane(0);
  //   } else if (!selectedSchool.includes("Private") && activePane === 0) {
  //     setActivePane(1);
  //   }
  //   // eslint-disable-next-line
  // }, [selectedSchoolClass, setActivePane]);

  useEffect(() => {
    async function getSections() {
      try {
        const response = await ApiClient.getSections();
        const schools = [...new Set(getSchoolNames(response.data))];
        const sections = getSectionNames(response.data);

        setSchools(schools);
        setSections(sections);
        setClassesStore(response.data);
      } catch {
        setNotification({
          type: notificationOptions.ERROR,
          text: "Could not fetch section/user data.",
          visible: true,
        });
      }
    }

    getSections();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilter("");
  }, [selectedFilterMethod, setFilter]);

  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <DashboardWrapper>
        <Title>{attachProperTitle(user)}</Title>
        <SectionsWrapper>
          <FilterSection>
            <SelectWrapper>
            <SelectHeader>Center</SelectHeader>
                <DropDown
                      onChange={handleSchoolSelect}
                      className={classNames({
                        active: FilterMethod.SELECT === selectedFilterMethod,
                      })}
                    >

                      <option value="no_selection">
                      {selectedSchool !== "no_selection" && selectedSchool ? "-- reset --" : "Select"}

                      </option>
                      {(schools || []).map((school: string) => (
                        <option key={school} value={school}>
                          {school}
                        </option>
                      ))
                      
                      
                      }
                    </DropDown>
            </SelectWrapper>
              <SelectWrapper>
              <SelectHeader>Class</SelectHeader>
                <DropDown
                      onChange={handleSchoolClassSelect}
                      className={classNames({
                        active: FilterMethod.SELECT === selectedFilterMethod,
                      })}
                    >
                      <option value="no_selection">
                      {"Select"}
                      </option>
                      {(schoolClasses || []).map((classes: string) => (
                        <option key={classes} value={classes.trim()}>
                          {classes.replace("class", "").trim()}
                        </option>
                      ))
                      
                      }
                    </DropDown>
              </SelectWrapper>
            {/* <InputWrapper>
              <FontAwesomeIcon
                onClick={() => handleSelectFilterMethod(FilterMethod.TYPE)}
                className={classNames("filter_icon", {
                  active: FilterMethod.TYPE === selectedFilterMethod,
                })}
                icon={faSearch}
              />
              <Input
                placeholder="search by class name"
                value={filter}
                type="text"
                onChange={updateFilter}
                className={classNames({
                  active: FilterMethod.TYPE === selectedFilterMethod,
                })}
              />
            </InputWrapper>
            <InputWrapper>
              <FontAwesomeIcon
                onClick={() => handleSelectFilterMethod(FilterMethod.SELECT)}
                className={classNames("filter_icon", {
                  active: FilterMethod.SELECT === selectedFilterMethod,
                })}
                icon={faFilter}
              />
              <SchoolSelect
                onChange={handleSchoolSelect}
                className={classNames({
                  active: FilterMethod.SELECT === selectedFilterMethod,
                })}
              >
                <option value="no_selection">
                  {selectedSchool !== "no_selection" && selectedSchool ? "-- reset --" : "filter by school"}
                </option>
                {(schools || []).map((school: string) => (
                  <option key={school} value={school}>
                    {school}
                  </option>
                ))
                
                }
              </SchoolSelect>
            </InputWrapper> */}
          </FilterSection>
          <SearchButton onClick={searchSchoolClass}>search</SearchButton>

          <TabMenu>
            <TabPaneButton className={classNames({ active: activePane === 0 })} onClick={() => setActivePane(0)}>
              Private Tuition
            </TabPaneButton>
            <TabPaneButton className={classNames({ active: activePane === 1 })} onClick={() => setActivePane(1)}>
              Schools
            </TabPaneButton>
          </TabMenu>
          <ClassList>
            {!classListWithFilter.length ? (
              <p className="no-results">
                <FontAwesomeIcon className="no-results-icon" icon={faExclamationTriangle} />
                no results
              </p>
            ) : (
              showSchools()
            )}
          </ClassList>
        </SectionsWrapper>
      </DashboardWrapper>
    </animated.div>
  );
}

export default AdminDashboard;

const DashboardWrapper = styled.div`
  width: 100vw;
`;

const TabPaneButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  border-bottom: 1px solid var(--neutral-150);
  height: 100%;
  width: 50%;
  cursor: pointer;
  background-color: var(--neutral-050);
  font-family: var(--normal-font-family);
  font-weight: bold;

  &.active {
    border: 1px solid var(--neutral-200);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-color: transparent;
    background-color: transparent;
  }
`;

const TabMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
`;

const Title = styled.h2`
  font-size: var(--page-title-font-size);
  text-align: center;
  color: var(--neutral-1000);
  margin-bottom: 3rem;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 2rem;
    font-size: 1.75rem;
  }
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;
  margin-bottom: 3rem;
  border-radius: var(--border-radius);
  padding: 1.5rem;
  padding-top: 0;
  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
`;

const ClassList = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  max-width: 100%;
  width: 1100px;

  .no-results {
    text-align: center;
    font-size: 2rem;
    color: var(--primary-600);
    margin: 5rem auto;

    .no-results-icon {
      margin-right: 0.625rem;
    }
  }
  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
  }
`;

const FilterSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
  }
`;

const InputWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 15px;

  .filter_icon {
    height: 100%;
    position: relative;
    top: 10px;
    margin-right: 10px;
    color: #ccc;
    cursor: pointer;

    &.active {
      color: var(--primary-500);
    }
  }
`;

const SearchButton = styled.button`
    margin: 0 auto;
    margin-bottom: 21px;
    border: 1px solid black;
    font-size: 1.3rem;
    border-radius: 30px;
    padding: 7px;
    color: white;
    box-shadow: black 1px 1px 3px 0px;
    background: linear-gradient(90deg, rgba(224, 43, 31, 1) 0%, rgba(242, 144, 31, 1) 100%);
`;

const SelectWrapper = styled.div`
  margin: 0 15px;
`;

const SelectHeader = styled.h4`
  margin-bottom: 3px;
  margin-left: 10px;
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  font-size: 1rem;
  outline: none;
  margin-bottom: 3rem;
  text-align: center;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease;
  height: 40px;

  &.active {
    width: 18.75rem;
    padding: 0.625rem 1.875rem;
    border-bottom: 1px solid var(--neutral-150);
  }

  @media screen and (max-width: 545px) and (orientation: portrait) {
    &.active {
      width: 18.75rem;
      padding: 0.625rem 1.875rem;
      border-bottom: 1px solid var(--neutral-150);
    }
  }
`;

const DropDown = styled.select`
 
  font-size: 18px;
  padding: 5px;
  margin-left: 10px;
  margin-bottom: 18px;
  overflow: hidden;
  min-width: 10rem;
  
`;

const SchoolSelect = styled.select`
  background-color: transparent;
  border: none;
  font-size: 1rem;
  outline: none;
  height: 40px;
  margin-bottom: 3rem;
  text-align: center;
  align-self: flex-end;
  margin-left: 10px;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease;

  &.active {
    width: 18.75rem;
    padding: 0.625rem 1.875rem;
    border-bottom: 1px solid var(--neutral-150);
  }

  option {
    text-align: center;
  }
`;
