import { useEffect } from "react";
import { roots } from "../_urls/";
import { roles } from "../_helpers/roles";

const useSetTheme = (user: any, location: any) => {
  useEffect(() => {
    if (
      user?.userprofile?.role === roles.STUDENT ||
      location.pathname === roots.LESSONS ||
      location.pathname === roots.LESSON
    ) {
      document.body.classList.add("student-theme");
    } else {
      document.body.classList.remove("student-theme");
    }
  }, [user, location]);
};

export default useSetTheme;
