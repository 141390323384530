import axios, { AxiosInstance } from "axios";
import { base as baseURL } from "../_endpoints/";
import ENDPOINTS from "./endpoints";
import { cleanCookie, getCookie } from "../_helpers/csrfCookie";

interface BaseApiInterface {}

type RecordType = {
  scheduled_lesson_class?: number;
  lesson?: number;
  datetime?: string;
};

class Api implements BaseApiInterface {
  eventService: AxiosInstance;
  authService: AxiosInstance;

  constructor(axios: AxiosInstance, authAxios: AxiosInstance) {
    this.eventService = axios;
    this.authService = authAxios;
  }

  async loginUser(username: string, password: string): Promise<any> {
    const bodyFormData = new FormData();
    bodyFormData.append("username", username);
    bodyFormData.append("password", password);

    const response = await this.authService.post(ENDPOINTS.LOGIN, bodyFormData);
    return response;
  }

  async logoutUser(): Promise<any> {
    const response = await this.authService.post(ENDPOINTS.LOGOUT);
    cleanCookie("csrftoken");
    return response;
  }

  async createClass(file: any, section: string): Promise<any> {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("section_id", section);

    const response = await this.authService.post(ENDPOINTS.CREATE_CLASS);
    return response;
  }

  async enableOrDisableManualUnlock(classId: number, status: number): Promise<any> {
    const data = { scheduled_lesson_class: classId, status: status };

    const response = await this.eventService.post(ENDPOINTS.MANUAL_ENBLE_OR_DISABLE, data);
    return response;
  }

  async LockOrUnlockLesson(classId: number, lessonNumber: number, status: number): Promise<any> {
    const data = { scheduled_lesson_class: classId, lesson_number: lessonNumber, status: status };

    const response = await this.eventService.post(ENDPOINTS.UNLOCK_LESSON, data);
    return response;
  }

  async getSections(): Promise<any> {
    const response = await this.eventService.get(ENDPOINTS.SECTIONS);
    return response;
  }

  async getCalendarByMonthAndYear(month: number, year: number): Promise<any> {
    const url = ENDPOINTS.GET_CALENDAR_BY_MONTH_AND_YEAR(month, year);
    const response = await this.eventService.get(url);
    return response;
  }

  async getAnalytics(): Promise<any> {
    const response = await this.eventService.get(ENDPOINTS.ANALYTICS);
    return response;
  }

  async getSection(classId: string | number): Promise<any> {
    const url = `${ENDPOINTS.SECTIONS}${classId}/`;
    const response = await this.eventService.get(url);
    return response;
  }

  async checkIfSlideCompleted(slide: number): Promise<any> {
    const url = ENDPOINTS.EVENT_COMPLETE(slide);
    const response = await this.eventService.get(url);
    return response;
  }

  async getLessons(): Promise<any> {
    const response = await this.eventService.get(ENDPOINTS.LESSONS);
    return response;
  }

  async getScheduledLessonsList(classId: number): Promise<any> {
    const url = ENDPOINTS.SCHEDULE + `?scheduled_lesson_class=${classId}`;
    const response = await this.eventService.get(url);
    return response;
  }

  async getSingleSlide(id?: any): Promise<any> {
    const response = await this.eventService.get(ENDPOINTS.SLIDE + id);
    return response;
  }

  async getStudentList(sectionId: number | string): Promise<any> {
    const url = ENDPOINTS.SECTIONS + sectionId + "/userlist";
    const response = await this.eventService.get(url);
    return response;
  }

  async sendCustomEvent(name: string): Promise<any> {
    const data = { action: "Awarded", name };
    const response = await this.eventService.post(ENDPOINTS.GAME_EVENT, data);
    return response;
  }

  async sendEventAction(data: any): Promise<any> {
    const response = await this.eventService.post(ENDPOINTS.GAME_EVENT, data);
    return response;
  }

  async sendSchedule(scheduleRecords: RecordType | RecordType[], scheduleType: string = "weekly") {
    const url = scheduleType === "weekly" ? ENDPOINTS.WEEKLY_SCHEDULE : ENDPOINTS.SCHEDULE;
    const data = scheduleRecords;

    const response = await this.eventService.post(url, data);
    return response;
  }

  async forceStudentProgress(classId: string): Promise<any> {
    const url = ENDPOINTS.FORCE_PROGRESS(classId);
    const response = await this.eventService.post(url);
    return response;
  }

  async getOrUpdateClass(classId: string): Promise<any> {
    const url = ENDPOINTS.GET_OR_UPDATE_CLASS(classId);
    const response = await this.eventService.get(url);
    return response;
  }

  async getUpdateUser(): Promise<any> {
    const response = await this.eventService.get(ENDPOINTS.USER);
    return response;
  }
}

const EventService = axios.create({
  responseType: "json",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-CSRFToken": getCookie("csrftoken"),
  },
  baseURL: baseURL,
});

const AuthService = axios.create({
  responseType: "json",
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
  baseURL: baseURL,
});

const ApiClient = new Api(EventService, AuthService);

export { Api, ApiClient };
