import React, { useEffect, useState, Suspense, lazy } from "react";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";
import { Title } from "../../../_components/common";
import { animated } from "react-spring";
import { loggedInUserAtom } from "../../../_store/auth/store";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { useRequest } from "../../../_customHooks/useRequest";
import { endpoints } from "../../../_endpoints";
import { LessonProps } from "../../../_store/lessons/models";
import { lessonsAtom } from "../../../_store/lessons/store";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";
import {
  useLocalStorage,
  LocalStorageKeys,
} from "../../../_helpers/localStorage";

const LessonInfo = lazy(() => import("./LessonInfo"));
const LiveView = lazy(() => import("./LiveView"));
const MyAchievements = lazy(() => import("./MyAchievements"));
const FullCourseAccessView = lazy(() => import("./FuLLCouseAccessView"));

function Dashboard() {
  const [data]: any = useRequest(endpoints.USER, "GET");
  const [, setUser] = useRecoilState(loggedInUserAtom);
  const [, setLocalStorageUser] = useLocalStorage<any>(
    LocalStorageKeys.USER,
    ""
  );
  const [lessonData]: any = useRequest(endpoints.LESSONS, "GET");
  const [currentLesson, setCurrentLesson] = useState(null);
  const [fullCourseAccessUrl, setFullCourseAccessUrl] = useState("");
  const [, setLessonData] = useRecoilState<LessonProps[]>(lessonsAtom);
  const pageTransition = useCustomTransition();

  useEffect(() => {
    if (data) {
      setLocalStorageUser(data[0]);
      setUser(data[0]);
      setFullCourseAccessUrl(data[0].userprofile.section.full_course_access_url);
    }
  }, [data, setLocalStorageUser, setUser, setFullCourseAccessUrl]);

  useMetaTags(defaultOptions.DASHBOARD);

  useEffect(() => {
    if (lessonData) {
      setLessonData(lessonData);

      const unlockedLessons = lessonData.filter(
        (lesson: LessonProps) => lesson.unlocked
      );
      unlockedLessons.length &&
        setCurrentLesson(unlockedLessons[unlockedLessons.length - 1]);
    }
  }, [lessonData, setLessonData, setCurrentLesson]);


  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <DashboardWrapper>
        <Title>Student Dashboard</Title>
        <SectionsWrapper>
          <LeftCol>
            <Suspense fallback={<div>Loading Lesson info...</div>}>
              <LessonInfo currentLesson={currentLesson} />
              <LiveView />
              { fullCourseAccessUrl.length !== 0?
               <FullCourseAccessView url={fullCourseAccessUrl}/>
               :""}
            </Suspense>
          </LeftCol>
          <RightCol>
            <Suspense fallback={<div>Loading Achievements info...</div>}>
              <MyAchievements />
            </Suspense>
          </RightCol>
        </SectionsWrapper>
      </DashboardWrapper>
    </animated.div>
  );
}

export default Dashboard;

const DashboardWrapper = styled.div`
  width: 100vw;
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: var(--container-width);
  margin: 0 auto;
  flex-grow: 2;
  position: relative;
  padding: 2rem;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    width: 100%;
  }
`;

const LeftCol = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  padding-right: 0.625rem;
  max-width: 48%;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    min-width: 100%;
    padding: 0.625rem;
  }
`;

const RightCol = styled.div`
  display: flex;
  flex-grow: 1;
  padding-left: 0.625rem;
  max-width: 48%;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    min-width: 100%;
    padding: 0.625rem;
  }
`;
