import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  hourSet,
  minuteSet,
  getISODateFromDatePicker,
  defaultValue,
  parseDateFromApiForSelect,
  i18n,
} from "../../../_helpers/dates";
import { DatePicker } from "react-vaadin-components";

type RecordType = {
  scheduled_lesson_class?: number;
  lesson?: number;
  datetime: string;
};

function SingleCustomScheduleRow({
  index,
  setRecord,
  record,
}: {
  index: number;
  setRecord: (record: RecordType) => void;
  record?: RecordType;
}) {
  const [selectedHour, setHour] = useState(hourSet[0]);
  const [selectedMinutes, setMinutes] = useState(minuteSet[0]);

  const [selectedDate, setDate] = useState("");

  useEffect(() => {
    if (record) {
      const [hours, minutes, dateFormatted] = parseDateFromApiForSelect(
        record.datetime
      );
      setHour(hours);
      setMinutes(minutes);
      setDate(dateFormatted);
    }
  }, [record]);

  useEffect(() => {
    if (
      selectedHour !== defaultValue &&
      selectedMinutes !== defaultValue &&
      selectedDate
    ) {
      const datetime = getISODateFromDatePicker(
        selectedDate,
        selectedHour,
        selectedMinutes
      );

      setRecord({ ...record, datetime });
    }
  }, [selectedHour, selectedMinutes, selectedDate]);

  return (
    <LessonRow>
      <p className="lesson-number">
        <b>Lesson {index}:</b>
      </p>
      <p>
        <b>Time:</b>
      </p>
      <div className="lesson-hour-date-form">
        <select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setHour(e.target.value)
          }
          value={selectedHour}
        >
          {hourSet.map((hour: number) => (
            <option key={hour}>{hour}</option>
          ))}
        </select>
        <select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setMinutes(e.target.value)
          }
          value={selectedMinutes}
        >
          {minuteSet.map((minutes: number) => (
            <option key={minutes}>{minutes}</option>
          ))}
        </select>
      </div>

      <p>
        <b>Date:</b>
      </p>

      <DatePicker
        i18n={i18n}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setDate(e.target.value)
        }
        value={selectedDate}
      ></DatePicker>
    </LessonRow>
  );
}

export default SingleCustomScheduleRow;

const LessonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
  border-bottom: 1px solid var(--neutral-100);

  &:nth-of-type(even) {
    background-color: var(--neutral-050);
  }

  @media screen and (max-width: 745px) and (orientation: portrait) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  p {
    margin: 0.625rem;

    @media screen and (max-width: 745px) and (orientation: portrait) {
      font-size: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      text-align: left;

      &.lesson-number {
        margin-bottom: 1rem;
        font-size: 1.1rem;
      }
    }

    &.lesson-number {
      width: 6.25rem;
    }
  }

  .lesson-hour-date-form {
    select {
      padding: 0.3125rem 0.625rem;
      border: 1px solid var(--neutral-100);
      border-radius: 0;
      outline: none;
      margin-right: 1rem;
      background: var(--neutral-050);
    }

    option {
      font-size: 1rem;
    }

    @media screen and (max-width: 745px) and (orientation: portrait) {
      display: flex;
      align-items: center;

      select {
        font-size: 1.1rem;
        margin: 0 0.1rem;
      }
    }
  }
`;
