import React from "react";
import styled from "@emotion/styled";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { ApiClient } from "../../../_axios";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

interface IEvent {
  title: string;
  tooltip: string;
  host_link: string;
  start: string;
  end: string;
}

interface EventTransform {
  host_link: string;
  scheduled_lesson: ScheduledTransform;
}

interface ScheduledTransform {
  datetime: any;
  id: number;
  lesson: number;
  lesson_title: string;
  scheduled_lesson_class: number;
  school_name: string;
  class_name: string;
}

const CalendarTeacher = () => {
  const [eventsList, setEventsList] = React.useState<IEvent[]>([]);
  const [date, setDate] = React.useState<Date>(new Date());

  React.useEffect(() => {
    const monthAndYear = moment(date).format("MM YYYY").split(" ");

    getCalendarDataByMonthAndYear(monthAndYear);
  }, [date]);

  const transformEvent = (response: EventTransform[]) => {
    return response.map(({ host_link, scheduled_lesson }) => {
      const { datetime, lesson_title, school_name, class_name } = scheduled_lesson;

      const time = datetime.match(/(\d{2}:\d{2})/)[0];

      return {
        title: `${lesson_title}, ${school_name}, ${class_name}, ${time}`,
        tooltip: `${lesson_title}, ${school_name}, ${class_name}, ${time}`,
        start: datetime,
        end: datetime,
        host_link,
      };
    });
  };

  const handleSelectEvent = (event: IEvent) => {
    window.open(event.host_link);
  };

  const getCalendarDataByMonthAndYear = async (monthAndYear: string[]) => {
    const response = await ApiClient.getCalendarByMonthAndYear(Number(monthAndYear[0]), Number(monthAndYear[1]));

    setEventsList(transformEvent([...response.data]));
  };

  return (
    <CalendarWrapper>
      <Calendar
        localizer={localizer}
        events={eventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800 }}
        onSelectEvent={handleSelectEvent}
        onNavigate={date => {
          setDate(date);
        }}
      />
    </CalendarWrapper>
  );
};

const CalendarWrapper = styled.div`
  margin: 20px auto 0;
  max-width: 1200px;
`;

export default CalendarTeacher;
