import axios, { CancelToken } from "axios";
import { endpoints } from "../_endpoints";

export function getScheduledLessonsList(
  class_id?: number,
  token?: CancelToken
) {
  return axios({
    method: "GET",
    url: `${endpoints.SCHEDULE}?scheduled_lesson_class=${class_id}`,
    cancelToken: token,
  });
}

export default getScheduledLessonsList;
