import XLSX from "xlsx-js-style";

export const downloadReport = (data: any, reportTitle: string, header: string[], filename: string) => {
    const wb = XLSX.utils.book_new();

    let reports: any[] = [
      [
        "", "", reportTitle, "", "", ""
      ],
      header.map((e:any) => Object({v: e, t: "s", s: { font: { bold: true, color: { rgb: "000000" } } }})),
    ];
    for (let element of data) {
      reports.push(Object.values(element).slice(1));
    }

    const ws = XLSX.utils.aoa_to_sheet(reports);
    ws["!cols"] = [{ wch: 20 }];
    ws.C1.width = 50;

    ws["!merges"] = [{ s: { c: 0, r: 0 }, e: { c: 1, r: 0 } }, { s: { c: 2, r: 0 }, e: { c: 7, r: 0 } }];

    XLSX.utils.book_append_sheet(wb, ws, "data");

    XLSX.writeFile(wb, `${filename}.xlsx`);
  }

