import React from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";

const myCourses = [
  {
    name: "Introduction to Arabic",
    link: "/lessons",
  },
];

function Courses() {
  useMetaTags(defaultOptions.COURSES);
  const history = useHistory();

  return (
    <ClassCreationWrapper>
      <Title>My Courses</Title>
      <MyCourses>
        {myCourses.map((course) => (
          <Course key={course.name}>
            <CourseLabel>
              <FontAwesomeIcon className="label-icon" icon={faUniversity} />
              {course.name}
            </CourseLabel>
            <Button onClick={() => history.push(course.link)}>
              View Course Content
            </Button>
          </Course>
        ))}
      </MyCourses>
    </ClassCreationWrapper>
  );
}

export default Courses;

const ClassCreationWrapper = styled.div`
  width: 100vw;
  padding: 0 1rem;
`;

const Title = styled.h2`
  font-size: var(--page-title-font-size);
  text-align: center;
  color: var(--neutral-1000);
  margin-bottom: 3rem;

  @media screen and (max-width: 545px) and (orientation: portrait) {
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 2rem;
    font-size: 1.75rem;
  }
`;

const MyCourses = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--container-width);
  margin: 0 auto;
  margin-bottom: 3rem;
`;

const CourseLabel = styled.div`
  font-size: 1.05rem;

  height: 100%;
  font-weight: bold;

  .label-icon {
    margin-right: 0.75rem;
    font-size: 1.15rem;
  }
`;

const Course = styled.div`
  background-color: var(--neutral-000);
  font-size: 1rem;
  outline: none;
  padding: 0.625rem 1.875rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: var(--neutral-050);
  box-shadow: var(--box-shadow-default);

  @media screen and (max-width: 545px) {
    max-width: 80vw;
    flex-direction: column;
    padding: 2rem;
  }
`;

const Button = styled.button`
  padding: var(--xl-btn-padding);
  font-size: var(--md-btn-font-size);
  outline: none;
  background-color: var(--primary-500);
  transition: all 0.2s ease;
  border: none;
  color: var(--neutral-000);
  cursor: pointer;

  &:hover {
    background-color: var(--primary-600);
  }

  @media screen and (max-width: 545px) {
    margin-top: 2rem;
  }
`;
