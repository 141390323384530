import React, { useState } from "react";
import styled from "@emotion/styled";
import SingleCustomScheduleRow from "./SingleCustomScheduleRow";
import { useRecoilValue } from "recoil";
import { sectionUploadSpinner } from "../../../_store/class/store";
import { endpoints } from "../../../_endpoints/";
import Spinner from "../../../_components/Spinner";
import axios, { AxiosResponse } from "axios";
import { useRequest } from "../../../_customHooks/useRequest";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { roots } from "../../../_urls";
import {
  notificationAtom,
  notificationOptions,
} from "../../../_store/notification/store";
import { useRecoilState } from "recoil";
import { handleError } from "../../../_helpers/errorHandler";

type RecordType = {
  scheduled_lesson_class?: number;
  lesson?: number;
  datetime: string;
};

function useEditSchedule(scheduleRecord: RecordType[], rowId: number) {
  const recordToEdit = scheduleRecord[0];
  const urlValue = endpoints.SCHEDULED_LESSON(rowId);
  const [, setNotification] = useRecoilState(notificationAtom);
  const [, setLoading] = useRecoilState(sectionUploadSpinner);

  return () => {
    setLoading(true);
    axios({
      method: "PATCH",
      url: urlValue,
      data: recordToEdit,
    })
      .then((response: AxiosResponse) => {
        setNotification({
          type: notificationOptions.SUCCESS,
          text: "Lesson edited successfully",
          visible: true,
        });
      })
      .catch((error) => {
        setNotification({
          type: notificationOptions.ERROR,
          text:
            handleError(error) || "Something went wrong, check inputted data",
          visible: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
}

function EditRow() {
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const params = useParams<{ classId: string; rowId: string }>();
  const history = useHistory();
  const [recordFromApi]: any = useRequest(
    endpoints.SCHEDULED_LESSON(Number(params.rowId)),
    "GET"
  );
  const [fullScheduleRecord, setFullScheduleRecord] = useState<RecordType[]>(
    []
  );
  const loading = useRecoilValue(sectionUploadSpinner);

  const redirectToScheduledLessons = () =>
    history.push(`${roots.CLASS}/${params.classId}/lesson-schedule`);

  const sendEditScheduleRequest = useEditSchedule(
    fullScheduleRecord,
    Number(params.rowId)
  );

  const sendRow = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendEditScheduleRequest();
    setFullScheduleRecord([]);
    redirectToScheduledLessons();
  };

  const updateRecord = (record: RecordType) => {
    setFullScheduleRecord([record]);
  };

  return (
    <Form onSubmit={sendRow}>
      <Title>Edit</Title>
      {loading ? (
        <Spinner />
      ) : (
        <ContentWrapper>
          <SingleCustomScheduleRow
            key={recordFromApi ? recordFromApi.lesson : "loading"}
            index={recordFromApi ? recordFromApi.lesson : "loading"}
            record={recordFromApi}
            setRecord={(record) => updateRecord(record)}
          />
          <button className="default-button" type="submit">
            Save changes
          </button>
        </ContentWrapper>
      )}
    </Form>
  );
}

export default EditRow;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  height: 100%;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    width: 100vw;
    padding: 1rem 2.1rem;
  }

  .default-button {
    padding: var(--xl-btn-padding);
    font-size: var(--lg-btn-font-size);
    outline: none;
    background-color: var(--primary-500);
    transition: all 0.2s ease;
    border: none;
    box-shadow: var(--box-shadow-default);
    color: var(--neutral-000);
    cursor: pointer;
    width: fit-content;
    margin-top: 1rem;

    &:hover {
      background-color: var(--primary-600);
    }
  }
`;

const Title = styled.h3`
  margin-bottom: 1.8rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  height: 100%;

  & button {
    margin-bottom: 2rem;
  }
`;
