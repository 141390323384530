import React from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  notificationAtom,
  notificationOptions,
} from "../../../_store/notification/store";
import { sectionClassIdAtom } from "../../../_store/class/store";
import { useRequest } from "../../../_customHooks/useRequest";
import { endpoints } from "../../../_endpoints/";
import axios, { AxiosResponse } from "axios";
import { animated } from "react-spring";
import { useHistory } from "react-router-dom";
import { useCustomTransition } from "../../../_customHooks/pageTransition";
import { useMetaTags, defaultOptions } from "../../../_customHooks/MetaTags";
import { sectionUploadSpinner } from "../../../_store/class/store";
import { handleError } from "../../../_helpers/errorHandler";
import classNames from "classnames";

function useForceStudentProgress(
  classId: string,
  forceProgressEnabled?: boolean
) {
  const [, setLoading] = useRecoilState(sectionUploadSpinner);
  const [, setNotification] = useRecoilState(notificationAtom);
  const urlValue = endpoints.FORCE_PROGRESS(classId);

  return () => {
    setLoading(true);
    axios({
      method: "POST",
      url: urlValue,
      data: {},
    })
      .then((response: AxiosResponse) => {
        setNotification({
          type: notificationOptions.SUCCESS,
          text: forceProgressEnabled
            ? "Force Student Progress is DISABLED"
            : "Force Student Progress is ENABLED",
          visible: true,
        });
      })
      .catch((error) => {
        setNotification({
          type: notificationOptions.ERROR,
          text:
            handleError(error) || "Something went wrong, check inputted data",
          visible: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
}

function ForceStudentProgress() {
  useMetaTags(defaultOptions.PROGRESS);

  const history = useHistory();
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const { classId } = useParams<{ classId: string }>();
  const sectionClassId = useRecoilValue(sectionClassIdAtom);

  if (sectionClassId === null) {
    history.push(`/class/${classId}`);
  }
  const [class_data]: any = useRequest(
    endpoints.GET_OR_UPDATE_CLASS(sectionClassId),
    "GET"
  );
  const pageTransition = useCustomTransition();

  const changeStudentProgress = useForceStudentProgress(
    String(sectionClassId),
    class_data?.force_progress_enabled
  );

  return (
    <animated.div className="spring_wrapper" style={pageTransition}>
      <LessonUnlockWrapper>
        <Title>Force Student Progress to Schedule</Title>
        <ContentWrapper>
          {class_data && (
            <>
              <Description>
                This is currently
                <b
                  className={classNames("student_progress_status", {
                    disabled: !class_data.force_progress_enabled,
                    enabled: class_data.force_progress_enabled,
                  })}
                >
                  {class_data.force_progress_enabled ? " ENABLED" : " DISABLED"}
                </b>
                <br />
                <br /> This option affects students in this class who have not
                yet completed lessons from previous classes. When students log
                in, their progress will be updated to the first slide of the
                most recent scheduled lesson. This will happen even if they did
                not complete the previous lesson(s). This ensures all students
                are up-to-date and studying the same lesson. If manual unlock is
                enabled, this option has no effect. If there is no schedule set,
                this option has no effect.
              </Description>

              <button
                className="default-button"
                onClick={changeStudentProgress}
              >
                {class_data?.force_progress_enabled
                  ? "Disable Force Student Progress"
                  : "Enable Force Student Progress"}
              </button>
            </>
          )}
        </ContentWrapper>
      </LessonUnlockWrapper>
    </animated.div>
  );
}

export default ForceStudentProgress;

const LessonUnlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  height: 100%;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    padding: 1rem;
  }
  .default-button {
    padding: var(--xl-btn-padding);
    font-size: var(--lg-btn-font-size);
    outline: none;
    background-color: var(--primary-500);
    transition: all 0.2s ease;
    border: none;
    box-shadow: var(--box-shadow-default);
    color: var(--neutral-000);
    cursor: pointer;
    width: fit-content;
    margin-top: 2rem;

    &:hover {
      background-color: var(--primary-600);
    }
  }
`;

const Title = styled.h3`
  margin-bottom: 1.8rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Description = styled.p`
  max-width: 50%;
  line-height: 150%;
  margin-top: 0;

  .student_progress_status {
    &.disabled {
      color: var(--secondary-600);
    }

    &.enabled {
      color: var(--primary-600);
    }
  }

  @media screen and (max-width: 745px) and (orientation: portrait) {
    max-width: 100%;
  }
`;
