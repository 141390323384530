import { useEffect } from "react";

enum defaultOptions {
  DASHBOARD = "Dashboard",
  CLASS_DETAIL = "Class Detail",
  STUDENT_DETAIL = "Student Detail",
  CLASS_CREATION = "Class Creation Tool",
  COURSES = "Courses",
  LOGIN = "Login Page",
  LESSONS = "Lesson",
  BADGES = "Badges",
  STATISTICS = "Statistics",
  SCHEDULE = "Class Schedule",
  LESSON_UNLOCK = "Manual Lesson Unlock",
  LESSON = "LESSON",
  ANALYTICS = "Analytics",
  PROGRESS = "Student progress",
  CLASS_RESULT = "Class Result",
  SCHOOL_RESULT = "School Result",
  TEACHING_SLIDES = "Teaching Slides",
  GUIDE_DOCS = "Guide & Documents"
}

const useMetaTags = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export { useMetaTags, defaultOptions };
