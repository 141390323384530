import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { User } from "../_store/auth/models";
import { roles } from "./roles";

export const isStudentRole = (user: User | null) => {
  return user?.userprofile?.role === roles.STUDENT;
};

export const isAdminRole = (user: User | null) => {
  return (
    user?.userprofile?.role !== roles.TEACHER &&
    user?.userprofile?.role !== roles.STUDENT &&
    user?.userprofile?.role !== roles.SCHOOL_ADMIN
  );
};

export const isTeacherRole = (user: User | null) => {
  return user?.userprofile?.role == roles.TEACHER;
};

export const isSchoolAdminRole = (user: User | null) => {
  return user?.userprofile?.role == roles.SCHOOL_ADMIN;
};

export const isWebAdminRole = (user: User | null) => {
  return user?.userprofile?.role == roles.WEB_ADMIN;
};

export const attachNavImage = (
  user: User | null,
  adminIcon: any,
  studentIcon: any,
  isHome: boolean = false,
) => {
  return isStudentRole(user) && !isHome ? (
    <img src={studentIcon} alt="" className="nav-icon nav-user" />
  ) : isHome ? (
    <img src={studentIcon} alt="" className="nav-icon" />
  ):(
    <FontAwesomeIcon
      className="nav-icon home-icon top-index"
      icon={adminIcon}
    />
  );
};
