import React, { useCallback, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faUnlock,
  faAngleLeft,
  faUsers,
  faEllipsisH,
  faTasks,
  faChartBar,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { roots } from "../../../_urls";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { classesAtom } from "../../../_store/class/store";
import { isNull } from "util";
import { isTeacherRole, isWebAdminRole } from "../../../_helpers/theme";
import { loggedInUserAtom } from "../../../_store/auth/store";

type ClassObjectType = {
  id: number;
  force_progress_enabled: boolean;
  full_name: string;
  manual_unlock_enabled: boolean;
  users_count: number;
  next_available_lesson_date: any;
  school_name: string;
};

const baseClassObj = {
  id: 0,
  force_progress_enabled: false,
  full_name: "",
  manual_unlock_enabled: false,
  users_count: 0,
  next_available_lesson_date: "",
  school_name: "",
};

function ClassSidebar() {
  // classId is really id of the section, this is because legacy reasons and needs to be fixed.
  const { classId } = useParams<{ classId: string }>();
  const user = useRecoilValue(loggedInUserAtom);
  const [sideMenuVisibility, changeSideMenuVisibility] = useState(false);
  const classList = useRecoilValue(classesAtom);
  const [sideBarState, setSideBarState] = useState({
    currentClassIndex: 0 as any,
    nextClassId: null as number | null,
    prevClassId: null as number | null,
    currentClassObj: baseClassObj as ClassObjectType,
    nextClassAvailable: false as boolean,
    prevClassAvailable: false as boolean,
  });

  const toggleSideMenuVisibility = () => changeSideMenuVisibility(!sideMenuVisibility);

  const getName = useCallback(() => {
    return sideBarState.currentClassObj?.full_name;
  }, [sideBarState]);

  const getSchoolName = useCallback(() => {
    return sideBarState.currentClassObj?.school_name;
  }, [sideBarState]);

  const generateNavLink = (context: string) => {
    switch (context) {
      case "next":
        return `${roots.CLASS}/${sideBarState.nextClassId}`;
      case "prev":
        return `${roots.CLASS}/${sideBarState.prevClassId}`;
    }
  };

  const classHasStudents = () => {
    return !classList.find(element => element.id === Number(classId) && element.users_count);
  };

  useEffect(() => {
    if (classList)
      classList.forEach((element, index) => {
        if (element.id === Number(classId)) {
          const nextClassId = classList.length > index + 1 ? classList[index + 1].id : null;
          const prevClassId = index > 0 ? classList[index - 1].id : null;

          setSideBarState({
            ...sideBarState,
            currentClassObj: {
              ...element,
              force_progress_enabled: false,
              next_available_lesson_date: "",
            },
            currentClassIndex: index,
            prevClassId,
            nextClassId,
            nextClassAvailable: !isNull(nextClassId) && true,
            prevClassAvailable: !isNull(prevClassId) && true,
          });
        }
      });
    // eslint-disable-next-line
  }, [classList]);

  return (
    <ClassNavigation sideMenuVisibility={sideMenuVisibility}>
      <FontAwesomeIcon className="menu-button" icon={faEllipsisH} onClick={toggleSideMenuVisibility} />
      <ClassName>{getName()}</ClassName>
      <div className="sub-nav-list">
        <ClassNavItem>
          <NavLink className="class-link" to={`${roots.CLASS}/${Number(classId)}`}>
            <FontAwesomeIcon className="nav-icon" icon={faUsers} />
            Current Class
          </NavLink>
        </ClassNavItem>
        <ClassNavItem>
          <NavLink
            to={generateNavLink("prev")}
            className={classNames("class-link", {
              "disabled-link": !sideBarState.prevClassAvailable,
            })}
          >
            <FontAwesomeIcon className="nav-icon" icon={faUsers} />
            Previous Class
          </NavLink>
        </ClassNavItem>
        <ClassNavItem>
          <NavLink
            className={classNames("class-link", {
              "disabled-link": !sideBarState.nextClassAvailable,
            })}
            to={generateNavLink("next")}
          >
            <FontAwesomeIcon className="nav-icon" icon={faUsers} />
            Next Class
          </NavLink>
        </ClassNavItem>
        <ClassNavItem>
          <NavLink
            className={"class-link"}
            to={`${roots.CLASS}/${Number(classId)}/result?class=${getName()}`}
          >
            <FontAwesomeIcon className="nav-icon" icon={faChartBar} />
            Class Results
          </NavLink>
        </ClassNavItem>
        <ClassNavItem>
          <NavLink
            className={"class-link"}
            to={`${roots.CLASS}/${Number(classId)}/school/result?school=${getSchoolName()}`}
          >
            <FontAwesomeIcon className="nav-icon" icon={faSchool} />
            School Results
          </NavLink>
        </ClassNavItem>
      {isWebAdminRole(user) || isTeacherRole(user) ?
          <>
        <ClassNavItem>
          <NavLink
            className={"class-link"}
            to={`${roots.CLASS}/${Number(classId)}/lesson-unlock`}
          >
            <FontAwesomeIcon className="nav-icon" icon={faUnlock} />
            Lesson Unlock
          </NavLink>
        </ClassNavItem>
        </>: null
        }
        {isWebAdminRole(user) ?
        <>
        <ClassNavItem>
          <NavLink
            className={"class-link"}
            to={`${roots.CLASS}/${Number(classId)}/lesson-schedule`}
          >
            <FontAwesomeIcon className="nav-icon" icon={faCalendarCheck} />
            Lesson Schedule
          </NavLink>
        </ClassNavItem>
        <ClassNavItem>
          <NavLink
            className={"class-link"}
            to={`${roots.CLASS}/${Number(classId)}/${roots.FORCE_PROGRESS}`}
          >
            <FontAwesomeIcon className="nav-icon" icon={faTasks} />
            Force Progress
          </NavLink>
        </ClassNavItem>
        </>: null
        }
        <ClassNavItem>
          <NavLink to={roots.DASHBOARD} className="class-link">
            <FontAwesomeIcon className="nav-icon" icon={faAngleLeft} />
            Back to Dashboard
          </NavLink>
        </ClassNavItem>
      </div>
    </ClassNavigation>
  );
}

export default ClassSidebar;

const ClassNavigation = styled.div<{ sideMenuVisibility: boolean }>`
  background-color: var(--neutral-100);
  padding: 1.2rem 2rem 1.2rem 1.2rem;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  height: 100%;
  overflow: hidden;
  width: var(--sidenav-width);
  min-width: var(--sidenav-width);

  .menu-button {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    //position: fixed;
    position: unset;
    box-shadow: var(--box-shadow-default);
    transition: all 0.3s ease;
    left: ${({ sideMenuVisibility }) =>
      sideMenuVisibility ? 0 : "calc(var(--sidenav-width) * -1)"};
    top: var(--header-height);

    .menu-button {
      display: flex;
      position: fixed;
      bottom: 1rem;
      right: 0;
      padding: 0.625rem;
      background-color: var(--neutral-1000);
      width: 2.5rem;
      height: 2.5rem;
      align-items: center;
      color: var(--neutral-000);
      justify-content: center;
      box-shadow: var(--box-shadow-default);
      cursor: pointer;
    }
  }
`;

const ClassName = styled.h3`
  max-width: var(--sidenav-width);
  margin-bottom: 2rem;
  line-height: 1.5;
`;

const ClassNavItem = styled.div`
  margin-bottom: 1.5rem;
  display: flex;

  .class-link {
    font-size: 1rem;
    color: var(--neutral-1000);
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    &.disabled-link {
      pointer-events: none;
      opacity: 0.3;
      pointer-events: none;

      :hover {
        color: var(--neutral-1000);
        cursor: disabled;
      }
    }

    .nav-icon {
      font-size: 1.1rem;
      width: 1.25rem;
      margin-right: 0.625rem;
    }
  }
`;
