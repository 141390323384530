import { atom } from "recoil";
import { Game } from "./models";

export const gamesAtom = atom({
  key: "gamesAtom",
  default: [] as Game[],
});

export const currentGameIndex = atom({
  key: "currentGameIndex",
  default: 8 as number,
});
