import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  initialState,
  notificationOptions,
} from "../_store/notification/store";
import { Spring } from "react-spring/renderprops";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

function Notification() {
  const [state, setState] = useRecoilState(notificationAtom);

  useEffect(() => {
    const destroyEffect = setTimeout(() => {
      setState(initialState);
    }, 4000);

    return () => {
      clearTimeout(destroyEffect);
    };
  }, [setState]);

  return (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {(props) => (
        <NotificationBody style={props} className={state.type}>
          <FontAwesomeIcon
            className="notification-icon"
            icon={
              state.type === notificationOptions.ERROR
                ? faExclamationCircle
                : faCheckCircle
            }
          />
          <Text>{state.text}</Text>
        </NotificationBody>
      )}
    </Spring>
  );
}

export default Notification;

const NotificationBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 25rem;
  height: auto;
  position: fixed;
  z-index: 999;
  left: 50%;
  transform: translateX(-12.5rem);
  border-radius: var(--border-radius);
  top: 0.625rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: var(--neutral-000);
  padding: 0.9375rem;
  box-shadow: var(--box-shadow-default);

  .notification-icon {
    font-size: 2rem;
    color: var(--neutral-000);
  }

  &.error {
    background-color: var(--secondary-500);
  }

  &.success {
    background-color: var(--primary-500);
  }
`;

const Text = styled.p`
  text-align: center;
  font-size: 1.25rem;
  color: var(--neutral-000);
  font-weight: bold;
  margin: 0.625rem auto;
`;
