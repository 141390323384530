/* eslint-disable */

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../_store/auth/store";
import { roots } from "../_urls";
import { LocalStorageKeys, useLocalStorage } from "../_helpers/localStorage";

export default function PrivateRoute({
  component: Component,
  ...rest
}: {
  component: React.ReactType;
  path: string;
  exact: boolean;
}) {
  const user = useRecoilValue(loggedInUserAtom);
  const [localStorageUser] = useLocalStorage(LocalStorageKeys.USER, "");

  return (
    <Route
      {...rest}
      render={(props) => {
        return user || localStorageUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: roots.LOGIN, state: { from: props.location } }}
          />
        );
      }}
    />
  );
}
