import { atom, selector } from "recoil";
import { ClassTypes, SectionType } from "./models";

export const classesAtom = atom<ClassTypes[]>({
  key: "classesAtom",
  default: [] as any[],
});

export const sectionsAtom = atom<SectionType[]>({
  key: "sectionsAtom",
  default: [],
});

export const sectionClassIdAtom = atom<number | null>({
  key: "sectionClassIdAtom",
  default: null,
});

export const sectionUploadSpinner = atom({
  key: "sectionUploadSpinner",
  default: false,
});

export const currentClassScheduleAtom = atom({
  key: "currentClassScheduleAtom",
  default: [],
});

export const classesFilterState = atom<string>({
  key: "classesFilterState",
  default: "",
});

export const filterClassesState = selector({
  key: "filterClassesState",
  get: ({ get }) => {
    const filter = get(classesFilterState);
    const list = get(classesAtom);

    if (!filter) return list;

    return list.filter((item: any) =>
      item.full_name.toLowerCase().includes(filter.toLowerCase())
    );
  },
});

export const filterBySchoolClasses = selector({
  key: "filterBySchoolClassesState",
  get: ({ get }) => {
    const filter = "Private";
    const searchFilter = get(classesFilterState);
    const list = get(classesAtom);

    const abcSortedList = [...list].sort((prev: ClassTypes, curr: ClassTypes) =>
      prev.full_name > curr.full_name ? 1 : -1
    );

    if (!filter) return abcSortedList;

    let classesBasedOnSchoolName = abcSortedList.filter((item: any) =>
      item.full_name.toLowerCase().includes(filter.toLowerCase())
    );

    if (searchFilter) {
      classesBasedOnSchoolName = classesBasedOnSchoolName.filter((item: any) =>
        item.full_name.toLowerCase().includes(searchFilter.toLowerCase())
      );
    }

    const restOfClasses = abcSortedList.filter((item: any) => {
      if (searchFilter) {
        return (
          !item.full_name.toLowerCase().includes(filter.toLowerCase()) &&
          item.full_name.toLowerCase().includes(searchFilter.toLowerCase())
        );
      } else {
        return !item.full_name.toLowerCase().includes(filter.toLowerCase());
      }
    });

    return [classesBasedOnSchoolName, restOfClasses];
  },
});
