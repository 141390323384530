import { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import { useRecoilState } from "recoil";
import { useLogout } from "../_customHooks/auth";
import {
  notificationAtom,
  notificationOptions,
} from "../_store/notification/store";
import { handleError } from "../_helpers/errorHandler";

export function useRequest(
  URL: string,
  METHOD: "GET" | "POST" | "PUT" | "DELETE",
  DATA: object = {}
) {
  const logout = useLogout();
  const [, setNotification] = useRecoilState(notificationAtom);
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const isData = Object.keys(DATA).length;
  const data = isData ? JSON.stringify(DATA) : DATA;

  // TODO: fixme
  // A warning appears in the console saying:
  // "Warning: Can't perform a React state update on an unmounted component."
  // Added simple fix for now

  useEffect(() => {
    let unmounted = false;
    setLoading(true);

    axios({
      method: METHOD,
      url: URL,
      withCredentials: true,
      data,
    })
      .then((response: AxiosResponse) => {
        if (!unmounted) {
          setResponseData(response.data);
        }
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          logout();
          setNotification({
            type: notificationOptions.ERROR,
            text:
              "You've been logged out. You've probably signed out of another session.",
            visible: true,
          });
        } else {
          if (!unmounted) {
            setNotification({
              type: notificationOptions.ERROR,
              text: handleError(error) || "Something went wrong",
              visible: true,
            });
          }
        }
      })
      .finally(() => {
        if (!unmounted) {
          setLoading(false);
        }
      });

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line
  }, []);

  return [responseData, loading];
}
