import axios, { AxiosResponse } from "axios";
import { endpoints } from "../_endpoints";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationOptions,
} from "../_store/notification/store";
import { sectionUploadSpinner } from "../_store/class/store";

export function useCreateClass(file: any, section: string) {
  const [, setNotification] = useRecoilState(notificationAtom);
  const [, setLoading] = useRecoilState(sectionUploadSpinner);

  const bodyFormData = new FormData();
  bodyFormData.append("file", file);
  bodyFormData.append("section_id", section);

  return () => {
    setLoading(true);
    axios({
      method: "POST",
      url: endpoints.CREATE_CLASS,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: AxiosResponse) => {
        setNotification({
          type: notificationOptions.SUCCESS,
          text: "Class created with success!",
          visible: true,
        });
      })
      .catch((error) => {
        setNotification({
          type: notificationOptions.ERROR,
          text: error.response.data,
          visible: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
}

export default useCreateClass;
