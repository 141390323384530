import React, { useState } from "react";
import styled from "@emotion/styled";

import WeeklyScheduleCreator from "./WeeklyScheduleCreator";
import CustomScheduleCreator from "./CustomScheduleCreator";

import ScheduleModal from "./ScheduleModal";

function ScheduleCreation() {
  const [weeklyScheduleForm, showWeeklyScheduleForm] = useState(false);
  const [customScheduleForm, showCustomScheduleForm] = useState(false);

  const handleCreatorVisibility = (type: string) => {
    if (type === "weekly") {
      showWeeklyScheduleForm(true);
      showCustomScheduleForm(false);
    } else {
      showWeeklyScheduleForm(false);
      showCustomScheduleForm(true);
    }
  };

  return (
    <ScheduleCreatorWrapper>
      <Title>
        Schedule creation
        {weeklyScheduleForm
          ? " - Weekly Schedule Creation"
          : customScheduleForm
          ? " - Custom Schedule Creation"
          : ""}
      </Title>
      <ContentWrapper>
        <button
          className="default-button"
          onClick={() => handleCreatorVisibility("weekly")}
        >
          Create new weekly schedule
        </button>
        {weeklyScheduleForm && !customScheduleForm && <WeeklyScheduleCreator />}
        {weeklyScheduleForm && <ScheduleModal />}
        <button
          className="default-button"
          onClick={() => handleCreatorVisibility("custom")}
        >
          Create new custom schedule
        </button>
        {customScheduleForm && !weeklyScheduleForm && <CustomScheduleCreator />}
        {customScheduleForm && <ScheduleModal />}
      </ContentWrapper>
    </ScheduleCreatorWrapper>
  );
}

export default ScheduleCreation;

const ScheduleCreatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  overflow: scroll;

  @media screen and (max-width: 745px) and (orientation: portrait) {
    padding: 1rem;
  }

  .default-button {
    padding: var(--xl-btn-padding);
    font-size: var(--lg-btn-font-size);
    outline: none;
    background-color: var(--primary-500);
    transition: all 0.2s ease;
    border: none;
    box-shadow: var(--box-shadow-default);
    color: var(--neutral-000);
    cursor: pointer;
    width: fit-content;
    margin-top: 1rem;

    &:hover {
      background-color: var(--primary-600);
    }
  }
`;

const Title = styled.h3`
  margin-bottom: 1.8rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
