import axios, { CancelToken } from "axios";
import { endpoints } from "../_endpoints";

export function getClassId(class_id?: number, token?: CancelToken) {
  return axios({
    method: "GET",
    url: `${endpoints.SECTIONS}${class_id}/`,
    cancelToken: token,
  });
}

export default getClassId;
