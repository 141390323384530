import { atom } from "recoil";
import { User } from "./models";

export const csrfTokenAtom = atom<string>({
  key: "csrfTokenAtom",
  default: "",
});

export const loggedInUserAtom = atom<User | null>({
  key: "loggedInUserAtom",
  default: null,
});
